import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 药品元数据映射-中西草映射等列表
export function list(data) {
  return request({
    url: '/term/term/match/list',
    method: 'get',
    params: data
  })
}
// 绑定
export function bind(data) {
  return request({
    url: '/term/term/match/bind',
    method: 'put',
    data: data
  })
}
// 解除绑定
export function unbind(data) {
  return request({
    url: '/term/term/match/unbind',
    method: 'put',
    params: data
  })
}

// 医院药品基本信息
export function info(data) {
  return request({
    url: '/dm/drug/match/drug/info',
    method: 'get',
    params: data
  })
}
// 通用名查找
export function search(data) {
  return request({
    url: '/dm/drug/match/generic/search',
    method: 'get',
    params: data
  })
}
// 上一条,下一条操作
export function index(data) {
  return request({
    url: '/dm/drug/match/proc/index',
    method: 'get',
    needSpical: true,
    params: data
  })
}
// 药品元数据映射保存
export function save(data) {
  return request({
    url: '/dm/drug/match/mapping/save',
    method: 'put',
    data: data
  })
}
// 知识库对应的药品信息查找
export function pdpList(data) {
  return request({
    url: '/dm/drug/match/pdp/list',
    method: 'get',
    params: data
  })
}
// 药品查询初始化
export function init(data) {
  return request({
    url: '/dm/drug/match/proc/init',
    method: 'get',
    params: data
  })
}
// 根据recordId获取该批次的药品集合
export function procList(data) {
  return request({
    url: '/dm/drug/match/proc/list',
    method: 'get',
    params: data
  })
}
// 药品元数据映射清空
export function clear(data) {
  return request({
    url: '/dm/drug/match/mapping/clear',
    method: 'put',
    params: data
  })
}
// 匹配完成
export function finish(data) {
  return request({
    url: '/dm/drug/match/finish',
    method: 'put',
    needSpical: true,
    params: data
  })
}
// 药品查询分页查找
export function page(data) {
  return request({
    url: '/dm/drug/match/proc/page',
    method: 'get',
    params: data
  })
}

// 匹配统计
export function submit(recordId) {
  return request({
    url: '/dm/drug/match/statistic/' + praseStrEmpty(recordId),
    method: 'get'
  })
}
// 药品查询分页查找
export function drugPage(data) {
  return request({
    url: '/dm/drug/match/proc/page',
    method: 'get',
    needSpical: true,
    params: data
  })
}
// 根据机构药品内容获取知识库对应的匹配结果
export function mapInfo(data) {
  return request({
    url: '/dm/drug/match/pdp/map/info',
    method: 'get',
    params: data
  })
}
// 高危药品分级概念域等
export function getListByDomainCode(domainCode) {
  return request({
    url: '/term/concept/getListByDomainCode/' + praseStrEmpty(domainCode),
    method: 'get'
  })
}
// 预览治理数据和治理报告内容
export function getEvalPreview(data) {
  return request({
    url: '/dm/drug/match/getEvalPreview',
    method: 'get',
    params: data
  })
}
// 刷新历史匹配数据-因知识库删除导致的问题
export function matchRefresh(data) {
  return request({
    url: '/dm/drug/match/refresh',
    method: 'put',
    params: data
  })
}
// 是否需要进行1-n默认关系确认
export function matchNeedDef(data) {
  return request({
    url: '/term/term/match/need/def',
    method: 'get',
    params: data
  })
}
// 1-n默认关系列表-全量数据,不进行分页
export function defList(data) {
  return request({
    url: '/term/term/match/def/list',
    method: 'get',
    params: data
  })
}
// 设置为默认值
export function setMatchDef(data) {
  return request({
    url: '/term/term/match/def',
    method: 'put',
    data: data
  })
}
