var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isAllData,
          expression: "isAllData",
        },
      ],
      staticClass: "app-container",
      attrs: { id: "mappingWrap" },
    },
    [
      _vm.firstFlag === 1
        ? _c(
            "div",
            [
              _vm.islookInfo
                ? _c(
                    "VueDragResize",
                    {
                      attrs: {
                        isActive: true,
                        x: 420,
                        y: 220,
                        w: 380,
                        h: 230,
                        z: 9999,
                        isResizable: false,
                      },
                      on: { dragging: _vm.resize },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "infoClose" },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#5DB730",
                                "font-weight": "bold",
                                "font-size": "16px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.procRowList.drugShow))]
                          ),
                          _vm.pdpRowList.dkbIdShowList
                            ? _c(
                                "span",
                                [
                                  _vm._v(" ("),
                                  _vm._l(
                                    _vm.pdpRowList.dkbIdShowList,
                                    function (item) {
                                      return _c(
                                        "span",
                                        { staticClass: "infoName" },
                                        [_vm._v(_vm._s(item))]
                                      )
                                    }
                                  ),
                                  _vm._v(") "),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c("el-button", { attrs: { type: "text" } }, [
                            _c("i", {
                              staticClass: "el-icon-close",
                              on: {
                                click: function ($event) {
                                  _vm.islookInfo = false
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "infoName" }, [
                        _vm._v(_vm._s(_vm.pdpRowList.genericName) + " "),
                      ]),
                      _c("p", { staticClass: "infoClass" }, [
                        _vm._v(_vm._s(_vm.pdpRowList.approvalNo)),
                      ]),
                      _c("p", { staticClass: "infoClass" }, [
                        _vm._v(_vm._s(_vm.pdpRowList.spec)),
                        _c(
                          "span",
                          { staticStyle: { "padding-left": "10px" } },
                          [_vm._v(_vm._s(_vm.pdpRowList.pdpMatTxt))]
                        ),
                      ]),
                      _c("p", { staticClass: "infoClass" }, [
                        _vm._v(_vm._s(_vm.pdpRowList.manufacturerName)),
                      ]),
                      _vm.pdpRowList.mapLevel === 1
                        ? _c("p", { staticClass: "infoClass" }, [
                            _vm._v(_vm._s(_vm.pdpRowList.nhsaCode)),
                          ])
                        : _vm._e(),
                      _vm.pdpRowList.mapLevel === 1
                        ? _c("p", { staticClass: "infoClass" }, [
                            _vm._v(_vm._s(_vm.pdpRowList.ustdCode)),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c("el-row", [
                _c("ul", { staticClass: "searchUl" }, [
                  _c("li", { staticClass: "searchLiLeft" }, [_vm._v("类别：")]),
                  _c(
                    "li",
                    {
                      staticClass: "searchLiRight",
                      staticStyle: { width: "80px" },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.typeChange },
                          model: {
                            value: _vm.checkedType,
                            callback: function ($$v) {
                              _vm.checkedType = $$v
                            },
                            expression: "checkedType",
                          },
                        },
                        _vm._l(_vm.typeList, function (item, index) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.index,
                              staticStyle: { width: "80px" },
                              attrs: { label: item },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("ul", { staticClass: "searchUl" }, [
                  _c("li", { staticClass: "searchLiLeft" }, [_vm._v("状态：")]),
                  _c(
                    "li",
                    {
                      staticClass: "searchLiRight",
                      staticStyle: { width: "64px" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "padding-top": "3px" },
                          on: { change: _vm.matchingChange },
                          model: {
                            value: _vm.checkedMatching,
                            callback: function ($$v) {
                              _vm.checkedMatching = $$v
                            },
                            expression: "checkedMatching",
                          },
                        },
                        _vm._l(_vm.matchingList, function (item) {
                          return _c(
                            "el-radio",
                            {
                              key: item.value,
                              staticStyle: { width: "60px" },
                              attrs: { label: item.value },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("ul", { staticClass: "searchUl" }, [
                  _c("li", { staticClass: "searchLiLeft" }, [_vm._v("标记：")]),
                  _c(
                    "li",
                    {
                      staticClass: "searchLiRight",
                      staticStyle: { width: "220px" },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.signChange },
                          model: {
                            value: _vm.checkedSign,
                            callback: function ($$v) {
                              _vm.checkedSign = $$v
                            },
                            expression: "checkedSign",
                          },
                        },
                        _vm._l(_vm.drugSignList, function (item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.name,
                              staticStyle: { width: "80px" },
                              attrs: {
                                label: item.value,
                                disabled: !_vm.isMatchDone,
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "ul",
                  { staticClass: "searchUl", staticStyle: { height: "35px" } },
                  [
                    _vm.firstFlag === 1
                      ? _c(
                          "li",
                          {
                            staticClass: "headerLiClass",
                            staticStyle: {
                              float: "left",
                              "margin-top": "-6px",
                              height: "45px",
                            },
                          },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "procListQueryForm",
                                attrs: {
                                  model: _vm.procListQuery,
                                  inline: true,
                                },
                                nativeOn: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "0px" },
                                    attrs: { label: "", prop: "searchValue" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "170px",
                                        height: "36px!important",
                                        "line-height": "36px",
                                      },
                                      attrs: {
                                        placeholder: "输入药品ID",
                                        clearable: "",
                                        size: "small",
                                        id: "plqSql",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.handlerKeyChange($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.procListQuery.searchValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.procListQuery,
                                            "searchValue",
                                            $$v
                                          )
                                        },
                                        expression: "procListQuery.searchValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      {
                        staticClass: "searchLiLeftBtn",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDrugQuery()
                              },
                            },
                          },
                          [_vm._v("开始查找")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "searchLiLeftBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleLocateFind()
                              },
                            },
                          },
                          [_vm._v("定位查找")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "searchLiLeftBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.dataRefresh()
                              },
                            },
                          },
                          [_vm._v("数据刷新")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "searchLiLeftBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "mini",
                              disabled: _vm.isLookShow,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.mappingDone()
                              },
                            },
                          },
                          [_vm._v("匹配完成")]
                        ),
                      ],
                      1
                    ),
                    _vm.mappingType === "edit" &&
                    _vm.from === "mappingMaintenance"
                      ? _c(
                          "li",
                          { staticClass: "searchLiLeftBtn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "warning",
                                  size: "mini",
                                  disabled: !_vm.isMappingEdit,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.mappingDone()
                                  },
                                },
                              },
                              [_vm._v("保存修改")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      { staticClass: "searchLiLeftBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.handleReback },
                          },
                          [_vm._v("退出")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "searchUl", staticStyle: { height: "35px" } },
                  [
                    _c(
                      "li",
                      { staticClass: "searchLiLeftBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              plain: "",
                              disabled: _vm.locationIndex < 2,
                            },
                            on: { click: _vm.previousStep },
                          },
                          [_vm._v("上一条(F4)")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "searchLiLeftBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: { click: _vm.nextStep },
                          },
                          [_vm._v("下一条(F1)")]
                        ),
                      ],
                      1
                    ),
                    _vm.from === "self"
                      ? _c(
                          "li",
                          { staticClass: "searchLiLeftBtn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.previewData },
                              },
                              [_vm._v("治理预览")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.from === "self"
                      ? _c(
                          "li",
                          { staticClass: "searchLiLeftBtn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  loading: _vm.downLoading,
                                },
                                on: { click: _vm.exportExcel },
                              },
                              [_vm._v("导出")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.procLoading,
                          expression: "procLoading",
                        },
                      ],
                      ref: "topTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.procList,
                        border: "",
                        stripe: "",
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        height: "180",
                        "highlight-current-row": "",
                        id: "topTable",
                      },
                      on: { "row-click": _vm.procListTableRowClick },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "index", label: "序号", width: "80" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orgDrugId",
                          label: "药品ID",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "genericName",
                          label: "药品名称",
                          width: "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "specText", label: "规格包装" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.specText) +
                                      "*" +
                                      _vm._s(scope.row.packFactor) +
                                      _vm._s(scope.row.minPackUnit) +
                                      "/" +
                                      _vm._s(scope.row.packUnit) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1739309528
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "manufacturerName",
                          label: "生产厂家",
                          width: "280",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "formName", label: "剂型" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "className", label: "类别" },
                      }),
                      _vm.isResult === false
                        ? _c("template", { slot: "empty" }, [
                            _c("img", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _c("template", { slot: "empty" }, [
                            _c("img", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: require("@/assets/images/no-left-data.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [
                              _vm._v("未查询到结果，请修改查询条件重试！"),
                            ]),
                          ]),
                      _vm.isMore && _vm.isResult === true
                        ? _c(
                            "p",
                            {
                              staticStyle: {
                                "text-align": "center",
                                color: "#ccc",
                              },
                              attrs: { slot: "append" },
                              slot: "append",
                            },
                            [_vm._v("加载中...")]
                          )
                        : _vm._e(),
                      _vm.isNoMore && _vm.procList.length > 1
                        ? _c(
                            "p",
                            {
                              staticStyle: {
                                "text-align": "center",
                                color: "#ccc",
                              },
                              attrs: { slot: "append" },
                              slot: "append",
                            },
                            [_vm._v("没有更多了...")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-container",
                { staticClass: "bottomWrap" },
                [
                  _c(
                    "el-aside",
                    { staticClass: "bottomLeft", attrs: { width: "380px" } },
                    [
                      _c(
                        "el-header",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { color: "#333333" },
                                  attrs: { span: 16 },
                                },
                                [
                                  _vm._v("医院药品基本信息 "),
                                  _vm.islookInfo
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.islookInfo = false
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-view",
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.islookInfo = true
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-view",
                                            staticStyle: { color: "#999999" },
                                          }),
                                        ]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    color: "#5DB730",
                                    "font-weight": "bold",
                                    "font-size": "16px",
                                  },
                                  attrs: { span: 8 },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.procRowList.drugShow) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-main",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "procRowList",
                              attrs: {
                                model: _vm.procRowList,
                                "label-width": "70px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "编号" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.procRowList.orgDrugId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "orgDrugId",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.orgDrugId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "贯标码" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.procRowList.nhsaCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "nhsaCode",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.nhsaCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  attrs: { label: "统编码" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.procRowList.ustdCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "ustdCode",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.ustdCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "药品名称" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    nativeOn: {
                                      mouseup: function ($event) {
                                        return _vm.getTxtContent($event, "mc")
                                      },
                                    },
                                    model: {
                                      value: _vm.procRowList.genericName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "genericName",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.genericName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品名" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    nativeOn: {
                                      mouseup: function ($event) {
                                        return _vm.getTxtContent($event, "spm")
                                      },
                                    },
                                    model: {
                                      value: _vm.procRowList.tradeName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "tradeName",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.tradeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "生产厂家" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    nativeOn: {
                                      mouseup: function ($event) {
                                        return _vm.getTxtContent($event, "sccj")
                                      },
                                    },
                                    model: {
                                      value: _vm.procRowList.manufacturerName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "manufacturerName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "procRowList.manufacturerName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "规格包装" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.procRowList.specPackaging,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "specPackaging",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.specPackaging",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "剂型" } },
                                        [
                                          _c("el-input", {
                                            attrs: { readonly: "" },
                                            model: {
                                              value: _vm.procRowList.formName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procRowList,
                                                  "formName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procRowList.formName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "类别" } },
                                        [
                                          _c("el-input", {
                                            attrs: { readonly: "" },
                                            model: {
                                              value: _vm.procRowList.className,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procRowList,
                                                  "className",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procRowList.className",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "批准文号" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    nativeOn: {
                                      mouseup: function ($event) {
                                        return _vm.getTxtContent($event, "pzwh")
                                      },
                                    },
                                    model: {
                                      value: _vm.procRowList.approvalNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "approvalNo",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.approvalNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "对应名称" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.procRowList.mapName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.procRowList,
                                          "mapName",
                                          $$v
                                        )
                                      },
                                      expression: "procRowList.mapName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticClass: "bottomRight" },
                    [
                      _c(
                        "el-header",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "pdpListQuery",
                              attrs: { model: _vm.pdpListQuery, inline: true },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "0px" },
                                      attrs: { label: "标记：", prop: "" },
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          attrs: { min: 1 },
                                          on: { change: _vm.signDrugChange },
                                          model: {
                                            value: _vm.checkedDrugSign,
                                            callback: function ($$v) {
                                              _vm.checkedDrugSign = $$v
                                            },
                                            expression: "checkedDrugSign",
                                          },
                                        },
                                        _vm._l(
                                          _vm.drugSignList,
                                          function (item) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: item.value,
                                                staticStyle: { width: "60px" },
                                                attrs: { label: item.value },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "200px",
                                      height: "36px!important",
                                      "line-height": "36px",
                                    },
                                    attrs: {
                                      placeholder: "输入关键字",
                                      clearable: "",
                                      size: "small",
                                    },
                                    on: {
                                      input: _vm.complexSearchValueInput,
                                      clear: _vm.resetClick,
                                    },
                                    model: {
                                      value: _vm.complexSearchValue,
                                      callback: function ($$v) {
                                        _vm.complexSearchValue = $$v
                                      },
                                      expression: "complexSearchValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-search",
                                        size: "mini",
                                        disabled: _vm.isDrugEmpty,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.firstQueryChange()
                                        },
                                      },
                                    },
                                    [_vm._v("查询(F6)")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.secondQueryChange()
                                        },
                                      },
                                    },
                                    [_vm._v("二次查询")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        disabled: _vm.isDrug || _vm.isDrugEmpty,
                                      },
                                      on: { click: _vm.commonNameClick },
                                    },
                                    [_vm._v("通用名查询(F5)")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "padding-right": "5px" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.centerDialogVisible = true
                                    },
                                  },
                                },
                                [_vm._v("查询条件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-main",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.pdpLoading,
                                      expression: "pdpLoading",
                                    },
                                  ],
                                  key: 1,
                                  ref: "table01",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.pdpTableData,
                                    border: "",
                                    stripe: "",
                                    "highlight-current-row": "",
                                    "header-cell-style": {
                                      "text-align": "center",
                                    },
                                    "cell-style": { "text-align": "center" },
                                    height: "180",
                                  },
                                  on: { "row-click": _vm.pdpListTableRowClick },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "genericName",
                                      label: "",
                                      "min-width": "5%",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    background: "#D7E3FA",
                                                    padding: "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("mapLevelSign")(
                                                          scope.row.mapLevel
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2196028653
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "genericName",
                                      label: "名称",
                                      "min-width": "15%",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    width: "250",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    scope.row.dkbIdShowList
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "inline-block",
                                                                  width: "60px",
                                                                  "text-align":
                                                                    "right",
                                                                },
                                                              },
                                                              [_vm._v("ID：")]
                                                            ),
                                                            _vm._l(
                                                              scope.row
                                                                .dkbIdShowList,
                                                              function (item) {
                                                                return _c(
                                                                  "span",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    scope.row.mapLevel === 1
                                                      ? _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                                width: "60px",
                                                                "text-align":
                                                                  "right",
                                                              },
                                                            },
                                                            [_vm._v("贯标码：")]
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .nhsaCode
                                                              )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                    scope.row.mapLevel === 1
                                                      ? _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                                width: "60px",
                                                                "text-align":
                                                                  "right",
                                                              },
                                                            },
                                                            [_vm._v("统编码：")]
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .ustdCode
                                                              )
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.genericName
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2548825366
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "formName",
                                      label: "剂型",
                                      "min-width": "10%",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "spec",
                                      label: "规格包装",
                                      "min-width": "20%",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "manufacturerName",
                                      label: "生产厂家",
                                      "min-width": "15%",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "10%",
                                      prop: "approvalNo",
                                      label: "批准文号",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "tradeName",
                                      "min-width": "10%",
                                      label: "商品名",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "pdpMatTxt",
                                      "min-width": "15%",
                                      label: "包装材料",
                                    },
                                  }),
                                  _vm.isTwoResult === false
                                    ? _c("template", { slot: "empty" }, [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/no-booking.svg"),
                                            alt: "",
                                          },
                                        }),
                                        _c("p", [_vm._v("暂无数据")]),
                                      ])
                                    : _c("template", { slot: "empty" }, [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/no-left-data.svg"),
                                            alt: "",
                                          },
                                        }),
                                        _c("p", [
                                          _vm._v(
                                            "未查询到结果，请修改查询条件重试！"
                                          ),
                                        ]),
                                      ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-container",
                            { staticClass: "mappingBottomRight" },
                            [
                              _c(
                                "el-main",
                                { staticClass: "remarkWrap" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "pdpRowList",
                                          attrs: {
                                            model: _vm.pdpRowList,
                                            "label-width": "80px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "名称" } },
                                                [
                                                  _c("el-input", {
                                                    attrs: { readonly: "" },
                                                    model: {
                                                      value:
                                                        _vm.pdpRowList
                                                          .genericName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pdpRowList,
                                                          "genericName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pdpRowList.genericName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "剂型" } },
                                                [
                                                  _c("el-input", {
                                                    attrs: { readonly: "" },
                                                    model: {
                                                      value:
                                                        _vm.pdpRowList.formName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pdpRowList,
                                                          "formName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pdpRowList.formName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "规格" } },
                                                [
                                                  _c("el-input", {
                                                    attrs: { readonly: "" },
                                                    model: {
                                                      value:
                                                        _vm.pdpRowList.spec,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pdpRowList,
                                                          "spec",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pdpRowList.spec",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "生产厂家" },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: { readonly: "" },
                                                    model: {
                                                      value:
                                                        _vm.pdpRowList
                                                          .manufacturerName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pdpRowList,
                                                          "manufacturerName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pdpRowList.manufacturerName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "商品名" } },
                                                [
                                                  _c("el-input", {
                                                    attrs: { readonly: "" },
                                                    model: {
                                                      value:
                                                        _vm.pdpRowList
                                                          .tradeName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pdpRowList,
                                                          "tradeName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pdpRowList.tradeName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "批准文号" },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: { readonly: "" },
                                                    model: {
                                                      value:
                                                        _vm.pdpRowList
                                                          .approvalNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pdpRowList,
                                                          "approvalNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pdpRowList.approvalNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "包装材料" },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: { readonly: "" },
                                                    model: {
                                                      value:
                                                        _vm.pdpRowList
                                                          .pdpMatTxt,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pdpRowList,
                                                          "pdpMatTxt",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pdpRowList.pdpMatTxt",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form",
                                    {
                                      ref: "baseDataForm",
                                      staticClass: "remarkForm",
                                      attrs: {
                                        model: _vm.baseDataForm,
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            display: "block",
                                            "min-height": "36px",
                                            height: "auto!important",
                                          },
                                          attrs: { label: "未匹配原因" },
                                        },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              model: {
                                                value: _vm.checkedUnmatched,
                                                callback: function ($$v) {
                                                  _vm.checkedUnmatched = $$v
                                                },
                                                expression: "checkedUnmatched",
                                              },
                                            },
                                            _vm._l(
                                              _vm.unmatched,
                                              function (item) {
                                                return _c(
                                                  "el-checkbox",
                                                  {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "" } },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    disabled: _vm.isLookShow,
                                                  },
                                                  on: {
                                                    click: _vm.accurateMatching,
                                                  },
                                                },
                                                [_vm._v("匹配(F2)")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    plain: "",
                                                    size: "mini",
                                                    disabled: _vm.isLookShow,
                                                  },
                                                  on: { click: _vm.clearEmpty },
                                                },
                                                [_vm._v("重置")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    disabled: _vm.isLookShow,
                                                  },
                                                  on: {
                                                    click: _vm.nonDrugClick,
                                                  },
                                                },
                                                [_vm._v("非药品")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    disabled: _vm.isLookShow,
                                                  },
                                                  on: {
                                                    click: _vm.MismatchClick,
                                                  },
                                                },
                                                [_vm._v("不匹配")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isPreviewData
                ? _c(
                    "VueDragResize",
                    {
                      attrs: {
                        isActive: true,
                        x: 400,
                        y: 70,
                        w: 1000,
                        h: 700,
                        z: 9999,
                        isResizable: false,
                      },
                      on: { dragging: _vm.resize },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "infoClose" },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "16px",
                              },
                            },
                            [_vm._v("治理预览")]
                          ),
                          _c("el-button", { attrs: { type: "text" } }, [
                            _c("i", {
                              staticClass: "el-icon-close",
                              on: {
                                click: function ($event) {
                                  _vm.isPreviewData = false
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { padding: "10px" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                border: "",
                                stripe: "",
                                "header-cell-style": {
                                  "text-align": "center",
                                  "font-weight": 900,
                                },
                                data: _vm.previewTableData,
                                loading: _vm.previewLoading,
                                height: "600",
                                "cell-class-name": _vm.setErrorColor,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "字段名称",
                                  align: "center",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "900",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.fieldName)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  855097035
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "originName",
                                  label: "原始数据",
                                  align: "left",
                                  "min-width": "150",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "stdName",
                                  label: "标准数据",
                                  align: "left",
                                  "min-width": "150",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "分析结果",
                                  align: "left",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.result === "2"
                                            ? _c("i", {
                                                staticClass: "el-icon-success",
                                                staticStyle: {
                                                  color: "#67C23A",
                                                },
                                              })
                                            : scope.row.result === "1"
                                            ? _c("i", {
                                                staticClass: "el-icon-question",
                                                staticStyle: {
                                                  color: "#409EFF",
                                                },
                                              })
                                            : scope.row.result === "3" ||
                                              scope.row.result === "4"
                                            ? _c("i", {
                                                staticClass: "el-icon-error",
                                                staticStyle: {
                                                  color: "#F56C6C",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    scope.row.result === "3" ||
                                                    scope.row.result === "4",
                                                  expression:
                                                    "scope.row.result === '3'|| scope.row.result === '4'",
                                                },
                                              ],
                                              staticStyle: {
                                                "background-color": "#E6ECFE",
                                                color: "#2D5AFA",
                                                display: "inline-block",
                                                padding: "5px",
                                                "margin-left": "4px",
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.errorTypeStr)
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3105691414
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "targetName",
                                  label: "治理数据",
                                  align: "left",
                                  "min-width": "150",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.statisticsDialogVisible,
            width: "600px",
            title: "统计",
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": _vm.statisticsHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.statisticsDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 22 } },
            [
              _c(
                "el-col",
                { staticClass: "statisticsTitle", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleNext" }, [
                    _vm._v("本院全部上传数据："),
                  ]),
                  _c("span", { staticClass: "statisticsRedNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.allDataCount)),
                  ]),
                  _vm._v("条 "),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsTitle", attrs: { span: 12 } },
                [_vm._v(" 对比本机构现存数据: ")]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsTitle", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleNext" }, [
                    _vm._v("无需匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsRedNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.noNeedMatchCount)),
                  ]),
                  _vm._v("条 "),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsTitle", attrs: { span: 12 } },
                [
                  _vm._v(" 新增"),
                  _c("span", { staticClass: "statisticsRedNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.addCount)),
                  ]),
                  _vm._v("条，修改"),
                  _c("span", { staticClass: "statisticsRedNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.updateCount)),
                  ]),
                  _vm._v("条，无变更"),
                  _c("span", { staticClass: "statisticsRedNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.noChangeCount)),
                  ]),
                  _vm._v("条 "),
                ]
              ),
            ],
            1
          ),
          _c("hr", {
            staticStyle: {
              "background-color": "#CAD9FC",
              height: "1px",
              border: "none",
            },
          }),
          _c(
            "el-row",
            { attrs: { gutter: 22 } },
            [
              _c(
                "el-col",
                { staticClass: "statisticsTitle", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleNext" }, [
                    _vm._v("已自动精确匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsRedNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.autoMatchCount)),
                  ]),
                  _vm._v("条 "),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsTitle", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleRight" }, [
                    _vm._v("未匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsRedNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.noMatchCount)),
                  ]),
                  _vm._v("条 "),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsContent", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleNext" }, [
                    _vm._v("西药匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsBlueNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.autoWmMatchCount) + "条"),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsContent", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleRight" }, [
                    _vm._v("西药匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsBlueNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.noWmMatchCount) + "条"),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsContent", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleNext" }, [
                    _vm._v("中成药匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsBlueNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.attoCpmMatchCount) + "条"),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsContent", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleRight" }, [
                    _vm._v("中成药匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsBlueNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.noCpmMatchCount) + "条"),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsContent", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleNext" }, [
                    _vm._v("中草药匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsBlueNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.autoChmMatchCount) + "条"),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "statisticsContent", attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "statisticsTitleRight" }, [
                    _vm._v("中草药匹配："),
                  ]),
                  _c("span", { staticClass: "statisticsBlueNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.noChmMatchCount) + "条"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "10px" }, attrs: { gutter: 22 } },
            [
              _c(
                "el-col",
                { staticStyle: { padding: "0px" }, attrs: { span: 12 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "statisticsTitle", attrs: { span: 24 } },
                    [
                      _c("span", { staticClass: "statisticsTitleNext" }, [
                        _vm._v("已手动匹配："),
                      ]),
                      _c("span", { staticClass: "statisticsRedNum" }, [
                        _vm._v(_vm._s(_vm.statisticsData.manualMatchCount)),
                      ]),
                      _vm._v("条 "),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "statisticsContent", attrs: { span: 24 } },
                    [
                      _c("span", { staticClass: "statisticsTitleNext" }, [
                        _vm._v("西药匹配："),
                      ]),
                      _c("span", { staticClass: "statisticsBlueNum" }, [
                        _vm._v(
                          _vm._s(_vm.statisticsData.manualWmMatchCount) + "条"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "statisticsContent", attrs: { span: 24 } },
                    [
                      _c("span", { staticClass: "statisticsTitleNext" }, [
                        _vm._v("中成药匹配："),
                      ]),
                      _c("span", { staticClass: "statisticsBlueNum" }, [
                        _vm._v(
                          _vm._s(_vm.statisticsData.manualCpmMatchCount) + "条"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "statisticsContent", attrs: { span: 24 } },
                    [
                      _c("span", { staticClass: "statisticsTitleNext" }, [
                        _vm._v("中草药匹配："),
                      ]),
                      _c("span", { staticClass: "statisticsBlueNum" }, [
                        _vm._v(
                          _vm._s(_vm.statisticsData.manualChmMatchCount) + "条"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "statisticsDiv" }, [
                  _c("span", { staticClass: "statisticsTitle" }, [
                    _vm._v("当前匹配度"),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "statisticsBlueNum" }, [
                    _vm._v(_vm._s(_vm.statisticsData.currentMatchRatio)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.statisticsHandleClose },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.commnonNameDialogVisible,
            width: "700px",
            title: "通用名查找",
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": _vm.commnonNameHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.commnonNameDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "commnonNameList",
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.commnonNameList },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px", float: "left" },
                    attrs: { placeholder: "输入通用名", clearable: "" },
                    model: {
                      value: _vm.commnonNameList.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.commnonNameList, "searchValue", $$v)
                      },
                      expression: "commnonNameList.searchValue",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        background: "#2B60F8",
                      },
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.queryCommnonNameList },
                    },
                    [_vm._v("查询 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "commnonName",
              attrs: {
                data: _vm.commnonNameOptions,
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                height: 400,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "name", label: "药品名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.choiceDrug(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.commnonNameList.pageNum,
              limit: _vm.commnonNameList.pageSize,
              "pager-count": 5,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.commnonNameList, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.commnonNameList, "pageSize", $event)
              },
              pagination: _vm.selectCommnonNameList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查询条件",
            visible: _vm.centerDialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 22 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("通用名查询：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.pdpListQuery.isGenericNameSearch === false
                        ? "否"
                        : "是"
                    )
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("通用名：")]),
                _c("span", [_vm._v(_vm._s(_vm.drugTym))]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("searchValue：")]),
                _c("span", [_vm._v(_vm._s(_vm.pdpListQuery.searchValue))]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("药品名称：")]),
                _c("span", [_vm._v(_vm._s(_vm.pdpListQuery.drugName))]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("商品名：")]),
                _c("span", [_vm._v(_vm._s(_vm.pdpListQuery.tradeName))]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("生产厂家：")]),
                _c("span", [_vm._v(_vm._s(_vm.pdpListQuery.manufacturerName))]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("批准文号：")]),
                _c("span", [_vm._v(_vm._s(_vm.pdpListQuery.approvalNo))]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "approvalWrapTop" }, [
            _c(
              "span",
              [
                _vm._v("当前代码映射存在多对一的数据，请先前往"),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.codeMatchingClick },
                  },
                  [_vm._v("【代码匹配】")]
                ),
                _vm._v("页面进行默认值设置！"),
              ],
              1
            ),
          ]),
          _c(
            "el-row",
            { staticClass: "approvalWrapBottom" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }