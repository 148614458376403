/**知识库版本升级 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
// 根据机构名称模糊查找-最多返回前50条
 function getOrgDataApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/record/org/search',
    method: 'GET',
    params: data
  })
}
// 知识库升级维护分页查询
 function getTableDataByPageApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/record/list',
    method: 'GET',
    params: data
  })
}
// 查看最新版的知识库版本信息
 function getInfoApi() {
  return request({
    url: '/dm/common/dkb/version/info',
    method: 'get'
  })
}
// mappingMaintenance
// 医院药品基本信息
 function drugBasicInfoApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/drug/info',
    method: 'get',
    params: data
  })
}
// 匹配完成
 function finishMatchedApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/finish',
    method: 'PUT',
    needSpical: true,
    params: data
  })
}
// 通用名查找
 function commonNameSearchApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/generic/search',
    method: 'GET',
    params: data
  })
}
// 上一条,下一条操作
 function nextOrPrevApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/index',
    method: 'GET',
    needSpical: true,
    params: data
  })
}
// 根据recordId获取该批次的药品集合(定位查找)
 function getDrugListApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/list',
    method: 'GET',
    params: data
  })
}
// 药品元数据映射清空(取消匹配)
 function clearMatchDataApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/mapping/clear',
    method: 'PUT',
    params: data
  })
}
// 药品元数据映射保存
 function saveMatchDataApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/mapping/save',
    method: 'PUT',
    data: data
  })
}
// 药品查询分页查找
 function searchDrugByPageApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/page',
    method: 'GET',
    needSpical: true,
    params: data
  })
}
// 知识库对应的药品信息查找
 function searchDrugOfKnowledgeApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/pdp/list',
    method: 'GET',
    params: data
  })
}
// 根据机构药品内容获取知识库对应的匹配结果
 function getMatchResultApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/pdp/map/info',
    method: 'GET',
    params: data
  })
}
// 药品查询初始化
 function getInitDataBySearchDrugApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/proc/init',
    method: 'GET',
    params: data
  })
}
// 根据上传记录ID获取该批次的药品类型集合
 function getDrugTypeApi (recordId) {
  return request({
    url: '/dm/dkb/uphold/mapping/proc/type/' + praseStrEmpty(recordId),
    method: 'get'
  })
}
// 查看当前状态
function getRecordStatusApi(data) {
  return request({
    url: '/dm/dkb/uphold/mapping/record/status',
    method: 'get',
    params: data
  })
}
export {
  getOrgDataApi,
  getTableDataByPageApi,
  getInfoApi,
  drugBasicInfoApi,
  finishMatchedApi,
  commonNameSearchApi,
  nextOrPrevApi,
  getDrugListApi,
  clearMatchDataApi,
  saveMatchDataApi,
  searchDrugByPageApi,
  searchDrugOfKnowledgeApi,
  getMatchResultApi,
  getInitDataBySearchDrugApi,
  getDrugTypeApi,
  getRecordStatusApi
}
export default {
  drugBasicInfoApi,
  finishMatchedApi,
  commonNameSearchApi,
  nextOrPrevApi,
  getDrugListApi,
  clearMatchDataApi,
  saveMatchDataApi,
  searchDrugByPageApi,
  searchDrugOfKnowledgeApi,
  getMatchResultApi,
  getInitDataBySearchDrugApi,
  getDrugTypeApi,
  getRecordStatusApi
}