<template>
  <div class="app-container" id="mappingWrap" v-loading="isAllData">
    <!--    选择药品进行匹配-->
    <div v-if="firstFlag === 1">
      <VueDragResize v-if="islookInfo"
                     :isActive="true"
                     :x="420"
                     :y="220"
                     :w="380"
                     :h="230"
                     :z="9999"
                     :isResizable="false"
                     v-on:dragging="resize">
        <div class="infoClose">
          <span style="color: #5DB730;font-weight: bold;font-size: 16px;">{{ procRowList.drugShow }}</span>
          <span v-if="pdpRowList.dkbIdShowList">
            (<span class="infoName" v-for="item in pdpRowList.dkbIdShowList">{{ item}}</span>)
          </span>
          <el-button type="text">
            <i class="el-icon-close" @click="islookInfo=false"></i>
          </el-button>
        </div>
        <p class="infoName">{{ pdpRowList.genericName }} </p>
        <!--        <div v-if="pdpRowList.dkbIdShowList">-->
        <!--          <p class="infoClass" v-for="item in pdpRowList.dkbIdShowList">{{ item}}</p>-->
        <!--        </div>-->
        <!--        <p class="infoClass">关联药品Id</p>-->
        <p class="infoClass">{{ pdpRowList.approvalNo }}</p>
        <p class="infoClass">{{ pdpRowList.spec }}<span style="padding-left: 10px;">{{ pdpRowList.pdpMatTxt }}</span></p>
        <p class="infoClass">{{ pdpRowList.manufacturerName }}</p>
        <p class="infoClass" v-if="pdpRowList.mapLevel === 1">{{ pdpRowList.nhsaCode }}</p>
        <p class="infoClass" v-if="pdpRowList.mapLevel === 1">{{ pdpRowList.ustdCode }}</p>
      </VueDragResize>
      <el-row>
        <ul class="searchUl">
          <li class="searchLiLeft">类别：</li>
          <li class="searchLiRight" style="width:80px;">
            <el-checkbox-group v-model="checkedType" @change="typeChange">
              <!--              <el-checkbox v-for="item in typeList" :label="item.name" :key="item.name" style="width:80px;">{{item.name}}</el-checkbox>-->
              <el-checkbox v-for="(item, index) in typeList" :label="item" :key="item.index" style="width:80px;">
                {{item}}
              </el-checkbox>
            </el-checkbox-group>
          </li>
        </ul>
        <ul class="searchUl">
          <li class="searchLiLeft">状态：</li>
          <li class="searchLiRight" style="width:64px;">
            <el-radio-group v-model="checkedMatching" @change="matchingChange" style="padding-top:3px;">
              <el-radio v-for="item in matchingList" :label="item.value" :key="item.value" style="width:60px;">{{item.name}}</el-radio>
            </el-radio-group>
          </li>
        </ul>
        <ul class="searchUl">
          <li class="searchLiLeft">标记：</li>
          <li class="searchLiRight" style="width:220px;">
            <el-checkbox-group v-model="checkedSign" @change="signChange">
              <el-checkbox v-for="item in drugSignList" :label="item.value" :key="item.name" style="width:80px;" :disabled="!isMatchDone">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </li>
        </ul>
        <ul class="searchUl" style="height: 35px;">
          <li class="headerLiClass" v-if="firstFlag === 1" style="float:left;margin-top:-6px;height: 45px;">
            <el-form :model="procListQuery" ref="procListQueryForm" :inline="true" @submit.native.prevent>
              <el-form-item label='' prop="searchValue" style="margin-bottom: 0px;">
                <el-input v-model="procListQuery.searchValue"
                          placeholder="输入药品ID"
                          clearable
                          size="small"
                          style="width: 170px;height: 36px!important;line-height: 36px;"
                          id="plqSql"
                          @keyup.native="handlerKeyChange($event)"/>
              </el-form-item>
            </el-form>
          </li>
          <li class="searchLiLeftBtn" style="margin-bottom: 5px;">
            <el-button type="primary" size="mini" @click="handleDrugQuery()">开始查找</el-button>
          </li>
          <li class="searchLiLeftBtn">
            <el-button type="primary" size="mini" @click="handleLocateFind()">定位查找</el-button>
          </li>
          <li class="searchLiLeftBtn">
            <el-button type="primary" size="mini" @click="dataRefresh()">数据刷新</el-button>
          </li>
          <!--          <li class="searchLiLeftBtn">-->
          <!--            <el-button type="warning" size="mini" @click="handleStatistics()">统计</el-button>-->
          <!--          </li>-->
          <li class="searchLiLeftBtn">
            <el-button type="success" size="mini" :disabled="isLookShow" @click="mappingDone()">匹配完成</el-button>
          </li>
          <!--映射关系维护操作-->
          <li
            v-if="mappingType === 'edit' && from === 'mappingMaintenance'"
            class="searchLiLeftBtn">
            <el-button
              type="warning"
              size="mini"
              :disabled="!isMappingEdit"
              @click="mappingDone()">保存修改</el-button>
          </li>
          <li class="searchLiLeftBtn">
            <el-button size="mini" @click="handleReback">退出</el-button>
          </li>
        </ul>
        <ul class="searchUl" style="height: 35px;">
          <li class="searchLiLeftBtn">
            <el-button size="mini" @click="previousStep" type="primary" plain :disabled="locationIndex < 2">上一条(F4)</el-button>
          </li>
          <li class="searchLiLeftBtn">
            <el-button size="mini" @click="nextStep" type="primary" plain>下一条(F1)</el-button>
          </li>
          <li v-if="from === 'self'" class="searchLiLeftBtn">
            <el-button type="primary" size="mini"
                       @click="previewData">治理预览</el-button>
          </li>
          <li v-if="from === 'self'" class="searchLiLeftBtn">
            <!--            v-loading.fullscreen.lock="downLoading"-->
            <el-button type="primary" size="mini"
                       @click="exportExcel"
                       :loading="downLoading">导出</el-button>
          </li>
        </ul>
      </el-row>
      <el-row>
        <el-table
          v-loading="procLoading"
          :data="procList"
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          style="width: 100%"
          height="180"
          highlight-current-row
          id="topTable"
          ref="topTable"
          @row-click="procListTableRowClick">
          <el-table-column
            prop="index"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            prop="orgDrugId"
            label="药品ID"
            width="150">
          </el-table-column>
          <el-table-column
            prop="genericName"
            label="药品名称"
            width="180">
          </el-table-column>
          <el-table-column
            prop="specText"
            label="规格包装">
            <template slot-scope="scope">
              {{scope.row.specText}}*{{scope.row.packFactor}}{{scope.row.minPackUnit}}/{{scope.row.packUnit	}}
            </template>
          </el-table-column>
          <el-table-column
            prop="manufacturerName"
            label="生产厂家"
            width="280">
          </el-table-column>
          <el-table-column
            prop="formName"
            label="剂型">
          </el-table-column>
          <el-table-column
            prop="className"
            label="类别">
          </el-table-column>
          <template slot="empty" v-if="isResult === false">
            <img src="@/assets/images/no-booking.svg" alt="" style="width:100px;height: 100px;">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="" style="width:100px;height: 100px;">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
          <p v-if="isMore && isResult === true" slot="append" style="text-align: center;color:#ccc;">加载中...</p>
          <p v-if="isNoMore && procList.length > 1" slot="append" style="text-align: center;color:#ccc;">没有更多了...</p>
        </el-table>
        <!--        v-infinite-scroll="load"-->
        <!--        nfinite-scroll-disabled="disabled"-->
        <!--        <p v-if="Tableloading">加载中...</p>-->
        <!--        <p v-if="noMore">没有更多了</p>-->

      </el-row>
      <el-container class="bottomWrap">
        <el-aside width="380px" class="bottomLeft">
          <el-header>
            <el-row>
              <el-col :span="16" style="color: #333333">医院药品基本信息
                <el-button
                  v-if="islookInfo"
                  type="text"
                  @click="islookInfo=false">
                  <i class="el-icon-view"></i>
                </el-button>
                <el-button
                  v-else
                  type="text"
                  @click="islookInfo=true">
                  <i class="el-icon-view" style="color: #999999"></i>
                </el-button>
              </el-col>
              <el-col :span="8" style="text-align: right;color: #5DB730;font-weight: bold;font-size: 16px;">
                {{ procRowList.drugShow }}
              </el-col>
            </el-row>
          </el-header>
          <el-main>
            <el-form ref="procRowList" :model="procRowList" label-width="70px">
              <el-form-item label="编号">
                <el-input v-model="procRowList.orgDrugId" readonly></el-input>
              </el-form-item>
              <el-form-item label="贯标码">
                <el-input v-model="procRowList.nhsaCode" readonly></el-input>
              </el-form-item>
              <el-form-item label="统编码" v-show="false">
                <el-input v-model="procRowList.ustdCode" readonly></el-input>
              </el-form-item>
              <el-form-item label="药品名称">
                <el-input v-model="procRowList.genericName" readonly @mouseup.native="getTxtContent($event, 'mc')"></el-input>
              </el-form-item>
              <el-form-item label="商品名">
                <el-input v-model="procRowList.tradeName" readonly @mouseup.native="getTxtContent($event, 'spm')"></el-input>
              </el-form-item>
              <el-form-item label="生产厂家">
                <el-input v-model="procRowList.manufacturerName" readonly @mouseup.native="getTxtContent($event, 'sccj')"></el-input>
              </el-form-item>
              <el-form-item label="规格包装">
                <el-input v-model="procRowList.specPackaging" readonly></el-input>
              </el-form-item>
              <!--              <el-form-item label="单位">-->
              <!--                <el-input v-model="procRowList.doseUnit" readonly></el-input>-->
              <!--              </el-form-item>-->
              <el-row>
                <el-col :span="12">
                  <el-form-item label="剂型">
                    <el-input v-model="procRowList.formName" readonly></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="类别">
                    <el-input v-model="procRowList.className" readonly></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="批准文号">
                <el-input v-model="procRowList.approvalNo" readonly @mouseup.native="getTxtContent($event, 'pzwh')"></el-input>
              </el-form-item>
              <el-form-item label="对应名称">
                <el-input v-model="procRowList.mapName" readonly></el-input>
              </el-form-item>
            </el-form>
          </el-main>
        </el-aside>
        <el-main class="bottomRight">
          <el-header>
            <el-form :model="pdpListQuery" ref="pdpListQuery" :inline="true" @submit.native.prevent>
              <el-row>
                <el-form-item label='标记：' prop="" style="margin-bottom:0px;">
                  <!--                  <el-radio-group v-model="checkedDrugSign" @change="signDrugChange">-->
                  <!--                    <el-radio v-for="item in drugSignList" :label="item.value" :key="item.value" style="width:60px;">{{item.name}}</el-radio>-->
                  <!--                  </el-radio-group>-->
                  <el-checkbox-group v-model="checkedDrugSign" :min="1" @change="signDrugChange">
                    <el-checkbox v-for="item in drugSignList" :label="item.value" :key="item.value" style="width:60px;">{{item.name}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-row>
              <el-form-item label=''>
                <el-input
                  v-model="complexSearchValue"
                  placeholder="输入关键字"
                  clearable
                  size="small"
                  style="width: 200px;height: 36px!important;line-height: 36px;"
                  @input="complexSearchValueInput"
                  @clear="resetClick"/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="firstQueryChange()" :disabled="isDrugEmpty">查询(F6)</el-button>
                <el-button type="primary" size="mini" @click="secondQueryChange()">二次查询</el-button>
              </el-form-item>
              <el-form-item prop="">
                <el-button type="primary" size="mini" @click="commonNameClick" :disabled="isDrug || isDrugEmpty">通用名查询(F5)</el-button>
              </el-form-item>
              <!--              <el-form-item label='二次查询' prop="">-->
              <!--                <el-switch-->
              <!--                  v-model="secondQuery"-->
              <!--                  active-color="#13ce66"-->
              <!--                  inactive-color="#ff4949"-->
              <!--                  @change="secondQueryChange">-->
              <!--                </el-switch>-->
              <!--              </el-form-item>-->
              <el-button type="text" @click="centerDialogVisible = true" style="padding-right: 5px;">查询条件</el-button>
              <!--              <el-form-item>-->
              <!--                <el-button size="small" @click="resetClick">重置</el-button>-->
              <!--              </el-form-item>-->
            </el-form>
          </el-header>
          <el-main>
            <el-row>
              <!--                v-if="checkedDrugSign === 1"-->
              <el-table
                ref="table01"
                v-loading="pdpLoading"
                :data="pdpTableData"
                border
                stripe
                highlight-current-row
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                style="width: 100%"
                height="180"
                @row-click="pdpListTableRowClick"
                :key="1">
                <!--包装材料 1-->
                <!--                :label="labelName + '名称'"-->
                <el-table-column
                  prop="genericName"
                  label=""
                  min-width="5%">
                  <template slot-scope="scope">
                    <div style="background: #D7E3FA;padding:5px;">
                      {{ scope.row.mapLevel | mapLevelSign }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="genericName"
                  label="名称"
                  min-width="15%">
                  <template slot-scope="scope">
                    <el-popover
                      placement="top-start"
                      width="250"
                      trigger="hover">
                      <div>
                        <div v-if="scope.row.dkbIdShowList">
                          <span style="display: inline-block;width: 60px;text-align: right;">ID：</span>
                          <span v-for="item in scope.row.dkbIdShowList">{{ item}}</span>
                        </div>
                        <div v-if="scope.row.mapLevel === 1">
                          <span style="display: inline-block;width: 60px;text-align: right;">贯标码：</span>
                          <span>{{ scope.row.nhsaCode }}</span>
                        </div>
                        <div v-if="scope.row.mapLevel === 1">
                          <span style="display: inline-block;width: 60px;text-align: right;">统编码：</span>
                          <span>{{ scope.row.ustdCode }}</span>
                        </div>
                      </div>
                      <span slot="reference">{{ scope.row.genericName }} </span>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="formName"
                  label="剂型"
                  min-width="10%">
                </el-table-column>
                <el-table-column
                  prop="spec"
                  label="规格包装"
                  min-width="20%">
                </el-table-column>
                <el-table-column
                  prop="manufacturerName"
                  label="生产厂家"
                  min-width="15%"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                  min-width="10%"
                  prop="approvalNo"
                  label="批准文号">
                </el-table-column>
                <el-table-column
                  prop="tradeName"
                  min-width="10%"
                  label="商品名">
                </el-table-column>
                <el-table-column
                  prop="pdpMatTxt"
                  min-width="15%"
                  label="包装材料">
                </el-table-column>
                <!--                <el-table-column-->
                <!--                  v-if="checkedDrugSign === 1"-->
                <!--                  prop="barcode69"-->
                <!--                  label="69码">-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                  v-if="checkedDrugSign === 1"-->
                <!--                  prop="inDateStr"-->
                <!--                  label="有效期">-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                  v-if="checkedDrugSign === 1"-->
                <!--                  prop="ustdCode"-->
                <!--                  label="统编码">-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                  v-if="checkedDrugSign === 1"-->
                <!--                  prop="packType"-->
                <!--                  label="包装类型">-->
                <!--                </el-table-column>-->
                <template slot="empty" v-if="isTwoResult === false">
                  <img src="@/assets/images/no-booking.svg" alt="" style="width:100px;height: 100px;">
                  <p>暂无数据</p>
                </template>
                <template slot="empty" v-else>
                  <img src="@/assets/images/no-left-data.svg" alt="" style="width:100px;height: 100px;">
                  <p>未查询到结果，请修改查询条件重试！</p>
                </template>
              </el-table>
              <!--              <el-table-->
              <!--                ref="table02"-->
              <!--                v-loading="pdpLoading"-->
              <!--                :data="pdpTableData"-->
              <!--                border-->
              <!--                stripe-->
              <!--                :header-cell-style="{'text-align':'center'}"-->
              <!--                :cell-style="{'text-align':'center'}"-->
              <!--                style="width: 100%"-->
              <!--                height="180"-->
              <!--                @row-click="pdpListTableRowClick"-->
              <!--                v-if="checkedDrugSign === 2"-->
              <!--                :key="2">-->
              <!--                &lt;!&ndash;药物包装 2&ndash;&gt;-->
              <!--                <el-table-column-->
              <!--                  prop="genericName"-->
              <!--                  :label="labelName + '名称'"-->
              <!--                  min-width="20%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="formName"-->
              <!--                  label="剂型"-->
              <!--                  min-width="10%">-->
              <!--                </el-table-column>-->
              <!--                &lt;!&ndash;                <el-table-column&ndash;&gt;-->
              <!--                &lt;!&ndash;                  v-if="checkedDrugSign === 2"&ndash;&gt;-->
              <!--                &lt;!&ndash;                  prop="packType"&ndash;&gt;-->
              <!--                &lt;!&ndash;                  label="包装类型">&ndash;&gt;-->
              <!--                &lt;!&ndash;                </el-table-column>&ndash;&gt;-->
              <!--                <el-table-column-->
              <!--                  prop="spec"-->
              <!--                  label="规格包装"-->
              <!--                  min-width="20%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="manufacturerName"-->
              <!--                  label="生产厂家"-->
              <!--                  show-overflow-tooltip-->
              <!--                  min-width="25%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="approvalNo"-->
              <!--                  label="批准文号"-->
              <!--                  min-width="10%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="tradeName"-->
              <!--                  label="商品名"-->
              <!--                  min-width="15%">-->
              <!--                </el-table-column>-->
              <!--                <template slot="empty" v-if="isTwoResult === false">-->
              <!--                  <img src="@/assets/images/no-booking.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>暂无数据</p>-->
              <!--                </template>-->
              <!--                <template slot="empty" v-else>-->
              <!--                  <img src="@/assets/images/no-left-data.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>未查询到结果，请修改查询条件重试！</p>-->
              <!--                </template>-->
              <!--              </el-table>-->
              <!--              <el-table-->
              <!--                ref="table03"-->
              <!--                v-loading="pdpLoading"-->
              <!--                :data="pdpTableData"-->
              <!--                border-->
              <!--                stripe-->
              <!--                :header-cell-style="{'text-align':'center'}"-->
              <!--                :cell-style="{'text-align':'center'}"-->
              <!--                style="width: 100%"-->
              <!--                height="180"-->
              <!--                @row-click="pdpListTableRowClick"-->
              <!--                v-if="checkedDrugSign === 3"-->
              <!--                :key="3">-->
              <!--                &lt;!&ndash;药品 3&ndash;&gt;-->
              <!--                <el-table-column-->
              <!--                  prop="genericName"-->
              <!--                  :label="labelName + '名称'"-->
              <!--                  min-width="20%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="formName"-->
              <!--                  label="剂型"-->
              <!--                  min-width="10%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="spec"-->
              <!--                  label="规格包装"-->
              <!--                  min-width="20%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="manufacturerName"-->
              <!--                  min-width="25%"-->
              <!--                  label="生产厂家"-->
              <!--                  show-overflow-tooltip-->
              <!--                >-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="approvalNo"-->
              <!--                  min-width="15%"-->
              <!--                  label="批准文号">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="tradeName"-->
              <!--                  min-width="15%"-->
              <!--                  label="商品名">-->
              <!--                </el-table-column>-->
              <!--                <template slot="empty" v-if="isTwoResult === false">-->
              <!--                  <img src="@/assets/images/no-booking.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>暂无数据</p>-->
              <!--                </template>-->
              <!--                <template slot="empty" v-else>-->
              <!--                  <img src="@/assets/images/no-left-data.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>未查询到结果，请修改查询条件重试！</p>-->
              <!--                </template>-->
              <!--              </el-table>-->
              <!--              <el-table-->
              <!--                ref="table04"-->
              <!--                v-loading="pdpLoading"-->
              <!--                :data="pdpTableData"-->
              <!--                border-->
              <!--                stripe-->
              <!--                :header-cell-style="{'text-align':'center'}"-->
              <!--                :cell-style="{'text-align':'center'}"-->
              <!--                style="width: 100%"-->
              <!--                height="180"-->
              <!--                @row-click="pdpListTableRowClick"-->
              <!--                v-if="checkedDrugSign === 4"-->
              <!--                :key="4">-->
              <!--                &lt;!&ndash;制剂规格 4&ndash;&gt;-->
              <!--                <el-table-column-->
              <!--                  prop="genericName"-->
              <!--                  :label="labelName + '名称'"-->
              <!--                  min-width="55%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="formName"-->
              <!--                  label="剂型"-->
              <!--                  min-width="15%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="spec"-->
              <!--                  label="规格"-->
              <!--                  min-width="20%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="drugClassName"-->
              <!--                  label="药物类别"-->
              <!--                  min-width="10%">-->
              <!--                </el-table-column>-->
              <!--                <template slot="empty" v-if="isTwoResult === false">-->
              <!--                  <img src="@/assets/images/no-booking.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>暂无数据</p>-->
              <!--                </template>-->
              <!--                <template slot="empty" v-else>-->
              <!--                  <img src="@/assets/images/no-left-data.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>未查询到结果，请修改查询条件重试！</p>-->
              <!--                </template>-->
              <!--              </el-table>-->
              <!--              <el-table-->
              <!--                ref="table05"-->
              <!--                v-loading="pdpLoading"-->
              <!--                :data="pdpTableData"-->
              <!--                border-->
              <!--                stripe-->
              <!--                :header-cell-style="{'text-align':'center'}"-->
              <!--                :cell-style="{'text-align':'center'}"-->
              <!--                style="width: 100%"-->
              <!--                height="180"-->
              <!--                @row-click="pdpListTableRowClick"-->
              <!--                v-if="checkedDrugSign === 5"-->
              <!--                :key="5">-->
              <!--                &lt;!&ndash;规格 5&ndash;&gt;-->
              <!--                <el-table-column-->
              <!--                  prop="genericName"-->
              <!--                  :label="labelName + '名称'"-->
              <!--                  min-width="55%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="formName"-->
              <!--                  label="剂型"-->
              <!--                  min-width="25%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="drugClassName"-->
              <!--                  label="药物类别"-->
              <!--                  min-width="20%">-->
              <!--                </el-table-column>-->
              <!--                <template slot="empty" v-if="isTwoResult === false">-->
              <!--                  <img src="@/assets/images/no-booking.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>暂无数据</p>-->
              <!--                </template>-->
              <!--                <template slot="empty" v-else>-->
              <!--                  <img src="@/assets/images/no-left-data.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>未查询到结果，请修改查询条件重试！</p>-->
              <!--                </template>-->
              <!--              </el-table>-->
              <!--              <el-table-->
              <!--                ref="table06"-->
              <!--                v-loading="pdpLoading"-->
              <!--                :data="pdpTableData"-->
              <!--                border-->
              <!--                stripe-->
              <!--                :header-cell-style="{'text-align':'center'}"-->
              <!--                :cell-style="{'text-align':'center'}"-->
              <!--                style="width: 100%"-->
              <!--                height="180"-->
              <!--                @row-click="pdpListTableRowClick"-->
              <!--                v-if="checkedDrugSign === 6"-->
              <!--                :key="6">-->
              <!--                &lt;!&ndash;药物 6&ndash;&gt;-->
              <!--                <el-table-column-->
              <!--                  prop="genericName"-->
              <!--                  :label="labelName + '名称'"-->
              <!--                  min-width="80%">-->
              <!--                </el-table-column>-->
              <!--                <el-table-column-->
              <!--                  prop="drugClassName"-->
              <!--                  label="药物类别"-->
              <!--                  min-width="20%">-->
              <!--                </el-table-column>-->
              <!--                <template slot="empty" v-if="isTwoResult === false">-->
              <!--                  <img src="@/assets/images/no-booking.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>暂无数据</p>-->
              <!--                </template>-->
              <!--                <template slot="empty" v-else>-->
              <!--                  <img src="@/assets/images/no-left-data.svg" alt="" style="width:100px;height: 100px;">-->
              <!--                  <p>未查询到结果，请修改查询条件重试！</p>-->
              <!--                </template>-->
              <!--              </el-table>-->
            </el-row>
            <el-container class="mappingBottomRight">
              <el-main class="remarkWrap">
                <el-row>
                  <el-form ref="pdpRowList" :model="pdpRowList" label-width="80px">
                    <el-col :span="6">
                      <el-form-item label="名称">
                        <el-input v-model="pdpRowList.genericName" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="剂型">
                        <el-input v-model="pdpRowList.formName" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="规格">
                        <el-input v-model="pdpRowList.spec" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="生产厂家">
                        <el-input v-model="pdpRowList.manufacturerName" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="商品名">
                        <el-input v-model="pdpRowList.tradeName" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="批准文号">
                        <el-input v-model="pdpRowList.approvalNo" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="包装材料">
                        <el-input v-model="pdpRowList.pdpMatTxt" readonly></el-input>
                      </el-form-item>
                    </el-col>
                  </el-form>
                </el-row>
                <el-form ref="baseDataForm" :model="baseDataForm" label-width="100px" class="remarkForm">
                  <el-form-item label="未匹配原因" style="display: block;min-height: 36px;height: auto!important;">
                    <el-checkbox-group v-model="checkedUnmatched">
                      <el-checkbox v-for="item in unmatched" :label="item.value" :key="item.value">{{item.name}}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="">
                    <el-checkbox-group>
                      <el-button type="primary" size="mini" @click="accurateMatching" :disabled="isLookShow">匹配(F2)</el-button>
                      <el-button type="danger" plain size="mini" @click="clearEmpty" :disabled="isLookShow">重置</el-button>
                      <el-button type="primary" size="mini" @click="nonDrugClick" :disabled="isLookShow">非药品</el-button>
                      <el-button type="primary" size="mini" @click="MismatchClick" :disabled="isLookShow">不匹配</el-button>
                    </el-checkbox-group>
                  </el-form-item>
                </el-form>
              </el-main>
            </el-container>
          </el-main>
        </el-main>
      </el-container>
      <VueDragResize v-if="isPreviewData"
                     :isActive="true"
                     :x="400"
                     :y="70"
                     :w="1000"
                     :h="700"
                     :z="9999"
                     :isResizable="false"
                     v-on:dragging="resize">
        <div class="infoClose">
          <span style="font-weight: bold;font-size: 16px;">治理预览</span>
          <el-button type="text">
            <i class="el-icon-close" @click="isPreviewData=false"></i>
          </el-button>
        </div>
        <div style="padding: 10px;">
          <el-table
            border
            stripe
            :header-cell-style="{'text-align':'center','font-weight': 900}"
            :data="previewTableData"
            :loading="previewLoading"
            height="600"
            :cell-class-name="setErrorColor"
            style="width: 100%">
            <el-table-column
              label="字段名称"
              align="center"
              width="150">
              <template slot-scope="scope">
                <div style="font-weight: 900">
                  <span>{{ scope.row.fieldName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="originName"
              label="原始数据"
              align="left"
              min-width="150">
            </el-table-column>
            <el-table-column
              prop="stdName"
              label="标准数据"
              align="left"
              min-width="150">
            </el-table-column>
            <el-table-column
              label="分析结果"
              align="left"
              min-width="100">
              <template slot-scope="scope">
                <i v-if="scope.row.result === '2'" class="el-icon-success" style="color:#67C23A;"></i>
                <i v-else-if="scope.row.result === '1'" class="el-icon-question" style="color:#409EFF;"></i>
                <i v-else-if="scope.row.result === '3'|| scope.row.result === '4'" class="el-icon-error" style="color:#F56C6C;"></i>
                <span style="background-color: #E6ECFE;color: #2D5AFA;display: inline-block;padding: 5px;margin-left: 4px;font-size: 14px;" v-show="scope.row.result === '3'|| scope.row.result === '4'">{{scope.row.errorTypeStr}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="targetName"
              label="治理数据"
              align="left"
              min-width="150">
            </el-table-column>
          </el-table>
        </div>
      </VueDragResize>
    </div>
    <el-dialog
      :visible.sync="statisticsDialogVisible"
      width="600px"
      title="统计"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="statisticsHandleClose">
      <el-row :gutter="22">
        <el-col :span="12" class="statisticsTitle">
          <span class="statisticsTitleNext">本院全部上传数据：</span><span class="statisticsRedNum">{{ statisticsData.allDataCount }}</span>条
        </el-col>
        <el-col :span="12" class="statisticsTitle">
          对比本机构现存数据:
        </el-col>
        <el-col :span="12" class="statisticsTitle">
          <span class="statisticsTitleNext">无需匹配：</span><span class="statisticsRedNum">{{ statisticsData.noNeedMatchCount }}</span>条
        </el-col>
        <el-col :span="12" class="statisticsTitle">
          新增<span class="statisticsRedNum">{{ statisticsData.addCount }}</span>条，修改<span class="statisticsRedNum">{{ statisticsData.updateCount }}</span>条，无变更<span class="statisticsRedNum">{{ statisticsData.noChangeCount }}</span>条
        </el-col>
      </el-row>
      <hr style='background-color:#CAD9FC; height:1px; border:none;'/>
      <el-row :gutter="22">
        <el-col :span="12" class="statisticsTitle">
          <span class="statisticsTitleNext">已自动精确匹配：</span><span class="statisticsRedNum">{{ statisticsData.autoMatchCount }}</span>条
        </el-col>
        <el-col :span="12" class="statisticsTitle">
          <span class="statisticsTitleRight">未匹配：</span><span class="statisticsRedNum">{{ statisticsData.noMatchCount }}</span>条
        </el-col>
        <el-col :span="12" class="statisticsContent">
          <span class="statisticsTitleNext">西药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.autoWmMatchCount }}条</span>
        </el-col>
        <el-col :span="12" class="statisticsContent">
          <span class="statisticsTitleRight">西药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.noWmMatchCount }}条</span>
        </el-col>
        <el-col :span="12" class="statisticsContent">
          <span class="statisticsTitleNext">中成药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.attoCpmMatchCount }}条</span>
        </el-col>
        <el-col :span="12" class="statisticsContent">
          <span class="statisticsTitleRight">中成药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.noCpmMatchCount }}条</span>
        </el-col>
        <el-col :span="12" class="statisticsContent">
          <span class="statisticsTitleNext">中草药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.autoChmMatchCount }}条</span>
        </el-col>
        <el-col :span="12" class="statisticsContent">
          <span class="statisticsTitleRight">中草药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.noChmMatchCount }}条</span>
        </el-col>
      </el-row>
      <el-row :gutter="22" style="margin-top: 10px;">
        <el-col :span="12" style="padding:0px;">
          <el-col :span="24" class="statisticsTitle">
            <span class="statisticsTitleNext">已手动匹配：</span><span class="statisticsRedNum">{{ statisticsData.manualMatchCount }}</span>条
          </el-col>
          <el-col :span="24" class="statisticsContent">
            <span class="statisticsTitleNext">西药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.manualWmMatchCount }}条</span>
          </el-col>
          <el-col :span="24" class="statisticsContent">
            <span class="statisticsTitleNext">中成药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.manualCpmMatchCount }}条</span>
          </el-col>
          <el-col :span="24" class="statisticsContent">
            <span class="statisticsTitleNext">中草药匹配：</span><span class="statisticsBlueNum">{{ statisticsData.manualChmMatchCount }}条</span>
          </el-col>
        </el-col>
        <el-col :span="12">
          <div class="statisticsDiv">
            <span class="statisticsTitle">当前匹配度</span>
            <br/>
            <span class="statisticsBlueNum">{{ statisticsData.currentMatchRatio }}</span>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="statisticsHandleClose">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="commnonNameDialogVisible"
      width="700px"
      title="通用名查找"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="commnonNameHandleClose">
      <el-form :inline="true" :model="commnonNameList" class="demo-form-inline" ref="commnonNameList">
        <el-form-item label="">
          <el-input placeholder="输入通用名" v-model="commnonNameList.searchValue" clearable
                    style="width: 200px;float: left;"
          />
          <el-button type="primary"
                     size="mini"
                     icon="el-icon-search"
                     style="margin-left: 20px;background: #2B60F8"
                     @click="queryCommnonNameList"
          >查询
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="commnonNameOptions"
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :height="400"
        ref="commnonName">
        <el-table-column property="name" label="药品名称"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="choiceDrug(scope.row)">选择</el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="commnonNameList.pageNum"
        :limit.sync="commnonNameList.pageSize"
        :pager-count=5
        @pagination="selectCommnonNameList"
      />
    </el-dialog>
    <el-dialog
      title="查询条件"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <el-row :gutter="22">
        <el-col :span="24"><span>通用名查询：</span><span>{{ pdpListQuery.isGenericNameSearch === false ? '否': '是' }}</span></el-col>
        <el-col :span="24"><span>通用名：</span><span>{{ drugTym }}</span></el-col>
        <el-col :span="24"><span>searchValue：</span><span>{{ pdpListQuery.searchValue }}</span></el-col>
        <el-col :span="24"><span>药品名称：</span><span>{{ pdpListQuery.drugName }}</span></el-col>
        <el-col :span="24"><span>商品名：</span><span>{{ pdpListQuery.tradeName }}</span></el-col>
        <el-col :span="24"><span>生产厂家：</span><span>{{ pdpListQuery.manufacturerName }}</span></el-col>
        <el-col :span="24"><span>批准文号：</span><span>{{ pdpListQuery.approvalNo }}</span></el-col>
      </el-row>
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--        <el-button @click="centerDialogVisible = false">取 消</el-button>-->
      <!--        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>-->
      <!--      </span>-->
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%">
      <div class="approvalWrapTop">
        <span>当前代码映射存在多对一的数据，请先前往<el-link type="primary" @click="codeMatchingClick">【代码匹配】</el-link>页面进行默认值设置！</span>
      </div>
      <el-row class="approvalWrapBottom">
        <el-col :span="24" style="text-align: center;">
<!--          <el-button size="mini" @click="dialogVisible = false">取 消</el-button>-->
          <el-button size="mini" type="primary" @click="dialogVisible = false">确 定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
<!--    <el-dialog-->
<!--      append-to-body-->
<!--      top="5px"-->
<!--      v-dialogDrag-->
<!--      title="治理预览"-->
<!--      :visible.sync="isPreviewData"-->
<!--      width="80%"-->
<!--      :close-on-click-modal="false"-->
<!--      id="previewDataDialog">-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
let finishMatchedApi,
  commonNameSearchApi, // 通用名查询
  nextOrPrevApi,
  getDrugListApi, // 定位查找
  clearMatchDataApi, // 取消匹配
  saveMatchDataApi,
  searchDrugByPageApi,
  searchDrugOfKnowledgeApi,
  getMatchResultApi,
  getInitDataBySearchDrugApi,
  getRecordStatusApi,
  getDrugTypeApi = null
import Cookies from "js-cookie"
import {
  procList,
  pdpList,
  search,
  index as indexFormList,
  save,
  clear,
  submit,
  finish,
  init,
  mapInfo,
  drugPage,
  getEvalPreview,
  matchRefresh
} from "@/api/term/drugElementMapping"
import {
  head,
  type,
  mappingDownload
} from "@/api/dm/uploadDocumentPretreatment"
import VueDragResize from 'vue-drag-resize';
/* import {
  finishMatchedApi,
  commonNameSearchApi, // 通用名查询
  nextOrPrevApi,
  getDrugListApi, // 定位查找
  clearMatchDataApi, // 取消匹配
  saveMatchDataApi,
  searchDrugByPageApi,
  searchDrugOfKnowledgeApi,
  getMatchResultApi,
  getInitDataBySearchDrugApi,
  getDrugTypeApi
} from apiSrc */
import conceptConst from '@/const/conceptConst'
import { getConcept } from '@/utils/concept'
const typeOptions = [
  // {name: '西药', value: 0},
  // {name: '中成药', value: 1},
  // {name: '草药', value: 2},
]
const matchingOptions = [
  {name: '全部', value: '1'},
  {name: '未做', value: '2'},
  {name: '做过', value: '3'},
  {name: '不做', value: '4'}
]
const matchedOptions = [
  {name: '生产厂商不同', value: 0},
  {name: '规格不同', value: 1},
  {name: '剂型不同', value: 2},
  {name: '其他', value: 3}
]
const unmatchedOptions  = [
  {name: '名称不明', value: '1'},
  {name: '剂型不明', value: '2'},
  {name: '规格不明', value: '3'},
  {name: '库中无此药', value: '4'},
  {name: '其他', value: '5'},
  {name: 'CFDA无此药', value: '6'},
  {name: '厂商不明', value: '7'},
  {name: '自制制剂', value: '8'},
  {name: '临床试验用药', value: '9'}
]
const drugSignOptions = [
  {name: '包装材料', value: 1},
  {name: '药品包装', value: 2},
  {name: '药品', value: 3},
  {name: '制剂规格', value: 4},
  {name: '制剂', value: 5},
  {name: '药物', value: 6}
]
export default {
  name: "drugMetadataMatchingMapping",
  components: {
    VueDragResize
  },
  props: {
    from: {
      type: String,
      default: 'self'
    }
  },
  data() {
    return {
      islookInfo: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      dkbVersion: '',
      drugTym: '',
      drugId: '',
      centerDialogVisible: false, // 查看查询条件
      isNoMore: false,
      isMore: false,
      queryParams_02: {
        aa:[]
      },
      isResult: false,
      isTwoResult: false,
      // 弹出层标题
      title: "",
      loading: false,
      statusStr: '',
      uploadTime: '',
      uploadTypeStr: '',
      firstFlag: 1,
      orgId: '',
      recordId: '',
      checkedType: [],
      // typeList: typeOptions,
      typeList: [],
      checkedMatching: '1',
      matchingList: matchingOptions,
      isMatchDone: false, // 匹配状态是否是做过
      checkedDrugSign: [1,2], // 默认包装材料、药品包装
      drugSignList: drugSignOptions,
      checkedSign: [],
      baseDataForm:{
        name: ''
      },
      checkedMatched: [],
      matched: matchedOptions,
      checkedUnmatched: [],
      unmatched: unmatchedOptions,
      // 根据recordId获取该批次的药品集合
      procListQuery:{
        classNameList: [],
        mapLevelList: [],
        locationSearch: false,
        matchStatus: '',
        recordId: '',
        searchValue: '',
        type: ''
      },
      complexSearchValue: '', // 划词二次联查搜索框
      secondQuery: false, // 划词二次查询
      isSecondQueryCopy: false, // 划词二次查询是否复制
      isSecondQC: false, // 判断是否是二次查询参数还是search
      // 知识库对应的药品信息查找
      pdpListQuery:{
        queryTypeList: [1,2],
        // queryTypeList: 1, // 对应包装材料
        isGenericNameSearch: false,
        isSecondLookup: false,
        searchValue: '',
        dkbVersion: ''
      },
      //药品集合
      procList: [],
      procRowList: {}, // 药品集合单行数据
      pdpTableData: [],
      pdpRowList: {},
      commnonNameDialogVisible: false, // 通用名遮罩层
      statisticsDialogVisible: false, // 统计遮罩层
      statisticsData: {}, // 统计数据
      commnonNameList: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn:undefined,
        isAsc:undefined,
        dkbVersion: ''
      },
      commnonNameOptions: [],
      mappingType: '', // 映射关系是查看还是维护操作
      isMappingEdit: false, // 映射关系是否被修改
      mappingStatus: '',
      total: 0,// 别名总条数
      commonNameId: '',
      labelName: '药品包装', // 制剂药品等名称前缀
      procLoading: false, // procList loading状态
      pdpLoading: false, // pdpList loading状态
      previewLoading: false, // 治理预览 loading状态
      Tableloading: false,
      count: 100,
      tableTotal: '',
      locationSearch: false, // 是否定位查找
      locationIndex: 0, // 定位查找的当前数据index（不默认第一条，点击下一条是第一条)
      isLookShow: false, // 是否是查看
      pageNum: 2, // 分页pageNum
      isAllData: false,
      isDrug: false, // 标记是否为药物，当为药物时候不能通用名查询
      downLoading: false, // 导出时候全局页面遮罩
      isDrugEmpty: false, // 标记是否为空
      isPreviewData: false, // 治理预览是弹出层
      orgDrugId: '', // 治理预览机构药品唯一码
      previewTableData: [], // 治理预览表格数据集
      dialogVisible: false
    };
  },
  filters: {
    mapLevelSign(val) {
      let labelName = ''
      if (val === 1) { // 包装材料
        labelName = '材'
      } else if (val === 2) { // 药品包装
        labelName = '包'
      } else if (val === 3) { // 药品
        labelName = '药'
      } else if (val === 4) { // 制剂规格
        labelName = '规'
      } else if (val === 5) { // 制剂
        labelName = '制'
      } else if (val === 6) { // 药物
        labelName = '物'
      }
      return labelName
    }
  },
  computed: {
    noMore () {
      return this.count >= this.tableTotal
    },
    disabled () {
      return this.Tableloading || this.noMore
    },
  },
  watch: {
    mappingStatus(newVal, oldVal) {
      if (newVal === '2') {
        // 映射关系被修改
        this.isMappingEdit = true
      }
    }
  },
  created() {
    const recordId = this.$route.query && this.$route.query.recordId
    const dkbVersion = this.$route.query && this.$route.query.dkbVersion
    this.dkbVersion = dkbVersion ? dkbVersion : ''
    if (recordId) {
      this.recordId = recordId
      this.headMessage()
    }
    const isLookShow = this.$route.query && this.$route.query.isLookShow
    if (isLookShow && this.from === 'self') {
      this.isLookShow = Boolean(isLookShow)
    } else if (this.from === 'self' && !isLookShow) {
      this.isLookShow = false
    } else if (this.from !== 'self') {
      if (this.mappingType === 'view') {
        // 查看页面
        this.isLookShow = true
      } else {
        this.isLookShow = false
      }
    }
    const orgId = this.$route.params && this.$route.params.orgId
    if (orgId) {
      this.orgId = orgId
    }
    // this.handleDrugQuery()
    // this.topTableScroll()
  },
  async activated() {
    let res = await this.importApiSrc()
    if (res) {
      finishMatchedApi = res.finishMatchedApi
      commonNameSearchApi = res.commonNameSearchApi // 通用名查询
      nextOrPrevApi = res.nextOrPrevApi
      getDrugListApi = res.getDrugListApi // 定位查找
      clearMatchDataApi = res.clearMatchDataApi, // 取消匹配
        saveMatchDataApi = res.saveMatchDataApi,
        searchDrugByPageApi = res.searchDrugByPageApi,
        searchDrugOfKnowledgeApi = res.searchDrugOfKnowledgeApi,
        getMatchResultApi = res.getMatchResultApi,
        getInitDataBySearchDrugApi = res.getInitDataBySearchDrugApi,
        getDrugTypeApi = res.getDrugTypeApi
      getRecordStatusApi = res.getRecordStatusApi
    }
    this.mappingType = this.$route.query && this.$route.query.type
    if (this.mappingType !== 'view' || this.from !== 'mappingMaintenance') {
      // 映射关系维护--查看--不调药品初始化接口
      await this.initFn() // 药品查询初始化
    }
    /* if (this.from === 'mappingMaintenance') {
      await this.getStatus()
    } */
    this.lbList() // 查询类别list
    const recordId = this.$route.query && this.$route.query.recordId
    if (recordId) {
      this.recordId = recordId
      this.headMessage()
    }
    const isLookShow = this.$route.query && this.$route.query.isLookShow
    if (isLookShow && this.from === 'self') {
      this.isLookShow = Boolean(isLookShow)
    } else if (this.from === 'self' && !isLookShow) {
      this.isLookShow = false
    } else if (this.from !== 'self') {
      if (this.mappingType === 'view') {
        // 查看页面
        this.isLookShow = true
      } else {
        this.isLookShow = false
      }
    }
    const orgId = this.$route.params && this.$route.params.orgId
    // console.log(orgId)
    if (orgId) {
      this.orgId = orgId
    } else {
    }
    if (this.$refs.table01) {
      this.$nextTick(() => {
        this.$refs.table01.doLayout();
      })
    }
    if (this.$refs.table02) {
      this.$nextTick(() => {
        this.$refs.table02.doLayout();
      });
    }
    if (this.$refs.table03) {
      this.$nextTick(() => {
        this.$refs.table03.doLayout();
      });
    }
    if (this.$refs.table04) {
      this.$nextTick(() => {
        this.$refs.table04.doLayout();
      });
    }
    if (this.$refs.table05) {
      this.$nextTick(() => {
        this.$refs.table05.doLayout();
      });
    }
    if (this.$refs.table06) {
      this.$nextTick(() => {
        this.$refs.table06.doLayout();
      });
    }
    // this.topTableScroll()
  },
  mounted() {
    window.onbeforeunload = function (e) {
      // console.log(e)
    }
    document.onkeydown = e => {
      let _this = this;
      // let key = window.event.keyCode
      if (e.keyCode === 112) { // F1 (下一条)
        window.event.preventDefault() //关闭浏览器快捷键
        _this.nextStep()
      } else if (e.keyCode === 113) { // F2 (精准匹配)
        window.event.preventDefault() //关闭浏览器快捷键
        _this.accurateMatching()
      } else if (e.keyCode === 115) { // F4 (上一条)
        window.event.preventDefault() //关闭浏览器快捷键
        _this.previousStep()
      } else if (e.keyCode === 116) { // F5 (通用名查询)
        window.event.preventDefault() //关闭浏览器快捷键
        _this.commonNameClick()
      } else if (e.keyCode === 117) { // F6 (查询)
        window.event.preventDefault() //关闭浏览器快捷键
        // _this.handlePdpDrugQuery()
        _this.firstQueryChange()
      }
      // console.log(e.keyCode)
    }
    this.$refs.topTable.bodyWrapper.addEventListener( 'scroll',(event) => {
      this.getDistance(event);
    });
  },
  deactivated() {
    Cookies.remove('drugMappingType')
  },
  methods: {
    // 治理预览
    async previewData() {
      if (this.orgDrugId === '') {
        this.$message.warning('请先选择一条数据再进行预览')
        return false
      }
      this.isPreviewData = true
      // console.log(this.orgDrugId)
      this.getPreviewData(this.orgDrugId)
    },
    // 获取预览数据
    async getPreviewData(orgDrugId) {
      this.previewLoading = true
      let obj = {}
      obj.recordId = this.recordId
      obj.orgDrugId = orgDrugId
      let res = await getEvalPreview(obj)
      if (res.code === 200) {
        this.previewLoading = false
        this.previewTableData = res.data.compareList
      }
    },
    // 下载文档
    async exportExcel() {
      this.downLoading = true
      // console.log(this.recordId)
      // let recordId = '1'
      let res = await mappingDownload(this.recordId)
      // console.log(res)
      this.download(res)
    },
    // 下载文件
    download (res) {
      if (!res) {
        return
      }
      this.downLoading = false
      // console.log(res)
      // console.log(res.headers)
      let downloadName = decodeURI(res.headers['filename'])
      let url = window.URL.createObjectURL(new Blob([res.data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', downloadName)

      document.body.appendChild(link)
      link.click()
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    async importApiSrc() {
      let fromType = Cookies.get('drugMappingType')
      return new Promise ((resolve, reject) => {
        if (fromType === 'mappingMaintenance') {
          import('@/api/dm/mappingMaintenance').then((res) => {
            return resolve(res)
          })
        } else if (fromType === 'knowledgeBaseVersionUpgrade') {
          import('@/api/dm/knowledgeBaseVersionUpgrade').then((res) => {
            return resolve(res)
          })
        } else {
          return resolve(null)
        }
      })
      // const apiSrc = (name) => () => import(`@/api/dm/${name}`)
    },
    // 映射关系维护场景：获取当前记录的状态
    async getStatus() {
      let res = await getRecordStatusApi({recordId: this.recordId})
      if (res.code === 200) {{
        this.mappingStatus = res.data
      }}
    },
    // 查询类别list
    async lbList() {
      //获取中西药物标识数据字典
      // await getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
      //   // console.log(result)
      //   this.typeList = result
      //   // this.cwMedicineIdentification = result
      // })
      if (this.from === 'self') {
        await type(this.recordId).then(result => {
          this.typeList = result.data
        })
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        let res = await getDrugTypeApi(this.recordId)
        if (res.code === 200) {
          this.typeList = res && res.data || []
        } else if (res.code !== 200 && res.code !== 500) {
          // this.$message({
          //   type: 'warning',
          //   message: res.msg
          // })
        }
      }
    },
    async getDistance(event) {
      // // console.log(event)
      let dom = event.target;
      const scrollDistance =dom.scrollHeight - dom.scrollTop - dom.clientHeight
      // console.log(dom.scrollHeight)
      // console.log(dom.scrollTop)
      // console.log(dom.clientHeight)
      // console.log(scrollDistance)
      if(scrollDistance <=0){//等于0证明已经到底，可以请求接口
        // dom.scrollTop === 0 且 scrollDistance <=0 代表没有数据比较少，没有纵向滚动条
        if (dom.scrollTop !== 0) {
          // do something
          // console.log('到底了')
          let obj = {}
          // console.log(this.pageNum)
          obj.pageNum = this.pageNum
          this.pageNum ++
          // console.log(this.pageNum)
          obj.pageSize = 100
          obj.recordId = this.recordId
          let res = null
          if (this.from === 'self') {
            res = await drugPage(obj)
          } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
            res = await searchDrugByPageApi(obj)
          }
          // this.procList = this.procList.concat(res.data.rows)
          // console.log(res)
          if (res.code === 10002) {
            this.isNoMore = true
            this.isMore = false
            this.procList = this.procList
          } else if (res.code === 200) {
            this.procList = this.procList.concat(res.data.rows)
            this.isNoMore = false
            this.isMore = true
          } else if (res.code === 10000) {
            // console.log('相关数据不存在')
            // this.isNoMore = true
            // this.isMore = false
            // this.procList = this.procList
            return false
          }
        } else {
          this.pageNum = 2 // 重置
          // console.log('没有滚动条')
        }
        // console.log(this.procList)
      }
    },
    resetClick() { // 重置(不重置标记位)
      let isSecondLookup = this.pdpListQuery.isSecondLookup
      // let queryTypeList = this.pdpListQuery.queryTypeList
      this.pdpListQuery = {
        // queryTypeList: [1,2],
        queryTypeList: this.checkedDrugSign,
        isGenericNameSearch: false,
        isSecondLookup: isSecondLookup,
        searchValue: ''
      }
      // console.log(this.pdpListQuery.queryTypeList)
      // console.log(queryTypeList)
      // this.checkedDrugSign = [1,2]
      this.complexSearchValue = ''
      // 通用名重置
      this.drugId = ''
      this.drugTym = ''
      this.pdpTableData = [] // 重置清空药品知识库查询列表
      this.isTwoResult = false
      // this.isSecondQueryCopy = false
      // this.isSecondQC = false
      // this.secondQuery = false
    },
    // 药品查询初始化
    async initFn() {
      let obj ={}
      obj.recordId = this.recordId
      let res = null
      if (this.from === 'self') {
        res = await init(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        res = await getInitDataBySearchDrugApi(obj)
      }
      if (res.code === 200) {
        if (res.data.needInit === true) {
          this.procList = res.data.drugShowBeanList // 药品list等于缓存匹配数据
          this.procListQuery = res.data.drugQueryBean
          if (res.data.drugQueryBean.mapLevelList) {
            this.checkedSign = res.data.drugQueryBean.mapLevelList
          }
          this.checkedMatching = res.data.drugQueryBean.matchStatus
          // console.log(this.checkedMatching)
          if (this.checkedMatching === '') {
            this.checkedMatching = '1'
            this.isMatchDone = false
          } else if (this.checkedMatching === '3') {
            this.isMatchDone = true
          } else {
            this.isMatchDone = false
          }
          // console.log(res.data.drugQueryBean.classNameList)
          if (res.data.drugQueryBean.classNameList) {
            // console.log(res.data.drugQueryBean.classNameList)
            this.checkedType = res.data.drugQueryBean.classNameList
          }
          if (res.data.index) {
            this.locationIndex = res.data.index
          }
        } else {
          this.handleDrugQuery() // 自动搜索数据
        }
      }
    },
    // 根据机构药品内容获取知识库对应的匹配结果
    async mapInfoFn(row) {
      this.pdpRowList = {} // 清空
      let obj ={}
      obj.orgDrugId = row.orgDrugId
      obj.orgId = this.orgId
      obj.recordId = this.recordId
      let res = null
      if (this.from === 'self') {
        res = await mapInfo(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade') {
        res = await getMatchResultApi(obj)
      } else if (this.from === 'mappingMaintenance') {
        if (this.mappingType === 'view') {
          obj.queryType = '1'
        } else if (this.mappingType === 'edit') {
          obj.queryType = '2'
        }
        res = await getMatchResultApi(obj)
      }
      if (res.code === 200) {
        if(res.data) {
          this.pdpRowList = res.data // 映射关系下对应数据值
        }
      }
    },
    /*初始化--加载字典等*/
    // async init() {
    //
    // },
    // 根据记录ID获取记录头信息
    async headMessage() {
      // console.log(this.recordId)
      let res = await head(this.recordId)
      this.statusStr = res.data && res.data.statusStr
      this.uploadTime = res.data && res.data.uploadTime
      this.uploadTypeStr = res.data && res.data.uploadTypeStr
      // console.log(res)
    },
    handlerKeyChange($ev) {
      // console.log($ev)
      // console.log($ev.keyCode)
    },
    // 类别选择
    typeChange(val) {
      // console.log(val)
      this.checkedType = val
      this.procListQuery.classNameList = this.checkedType
    },
    // 匹配状态选择
    matchingChange(val) {
      // console.log(val)
      this.checkedMatching = val
      this.procListQuery.matchStatus = this.checkedMatching
      this.checkedSign = []
      this.procListQuery.mapLevelList = []
      if (val === '3') {
        this.isMatchDone = true
      } else {
        this.isMatchDone = false
      }
    },
    // 药品标记选择
    signChange(val) {
      // console.log(val)
      this.checkedSign = val
      this.procListQuery.mapLevelList = this.checkedSign
    },
    // load () {
    //   this.loading = true
    //   setTimeout(() => {
    //     this.count += 2
    //     this.loading = false
    //   }, 2000)
    // }
    //数据刷新
    dataRefresh() {
      let that = this
      this.$confirm('确认要数据刷新吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        that.isAllData = true
        let obj = {}
        obj.recordId = that .recordId
        matchRefresh(obj).then(response => {
          // console.log(response)
          if (response.code === 200) {
            that.msgSuccess(response.msg)
          }
          that.isAllData = false

        })
      })
    },
    // 定位查找
    async handleLocateFind() {
      this.resetClick() // 重置
      if (this.procListQuery.searchValue === '' || this.procListQuery.searchValue === null || this.procListQuery.searchValue === undefined ) {
        this.$message({
          message: '请输入药品编码！',
          type: 'warning'
        })
        return false
      } else {
        // this.procListQuery.locationSearch = true
        this.locationSearch = true
        this.procListQuery.locationSearch = true
        this.procLoading = true
        this.procListQuery.recordId = this.recordId
        let res = null
        if (this.from === 'self') {
          res = await procList(this.procListQuery)
        } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
          if (this.mappingType === 'view' && this.from === 'mappingMaintenance') {
            this.procListQuery.queryType = '1'
          } else if (this.mappingType === 'edit' && this.from === 'mappingMaintenance') {
            this.procListQuery.queryType = '2'
          }
          res = await getDrugListApi(this.procListQuery)
        }
        if (res.code === 200) {
          this.procLoading = false
          if (res.data.rows) {
            this.procList = res.data.rows
            this.locationIndex = res.data.rows[0].index
          } else {
            this.procList = []
          }
          this.isMore = false
          this.isNoMore = false
        }
      }
    },
    // 获取当前匹配Table单条数据
    async getOneTable(index, recordId) {
      // console.log(index, recordId)
      // this.procRowList = {} // 清空
      // this.checkedUnmatched = [] // 清空
      let obj = {}
      obj.index = index
      obj.recordId = recordId
      let res = null
      if (this.from === 'self') {
        res = await indexFormList(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        if (this.mappingType === 'view' && this.from === 'mappingMaintenance') {
          obj.queryType = '1'
        } else if (this.mappingType === 'edit' && this.from === 'mappingMaintenance') {
          obj.queryType = '2'
        }
        res = await nextOrPrevApi(obj)
      }
      // console.log(res)
      if (res.code === 200) {
        // console.log(res)
        if (res.data.rows.length > 0) {
          this.procList = res.data.rows
          this.locationIndex = res.data.rows[0].index
          // console.log(res.data)
        }
      }
    },
    // 获取topTable单条数据
    async getTopTable(index, recordId) {
      // console.log(index, recordId)
      this.procRowList = {} // 清空
      this.checkedUnmatched = [] // 清空
      let obj = {}
      obj.index = index
      obj.recordId = recordId
      let res = null
      if (this.from === 'self') {
        res = await indexFormList(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        if (this.mappingType === 'view' && this.from === 'mappingMaintenance') {
          obj.queryType = '1'
        } else if (this.mappingType === 'edit' && this.from === 'mappingMaintenance') {
          obj.queryType = '2'
        }
        res = await nextOrPrevApi(obj)
      }
      if (res.code === 200) {
        if (res.data.rows.length > 0) {
          this.procRowList = res.data.rows[0]
          this.procRowList.specPackaging = res.data.rows[0].specText + '*' + res.data.rows[0].packFactor + res.data.rows[0].minPackUnit + '/' + res.data.rows[0].packUnit
          if(res.data.rows[0].mapFailReasonArr) {
            this.checkedUnmatched = res.data.rows[0].mapFailReasonArr
          }
        }
      }
    },
    // 上一条
    async previousStep() {
      this.resetClick() // 重置
      if (this.locationIndex == 0) {
        return false
      } else {
        let obj = {}
        obj.index = this.locationIndex -1
        this.locationIndex--
        obj.recordId = this.recordId
        obj.cacheFlag = 1
        let res = null
        if (this.from === 'self') {
          res = await indexFormList(obj)
        } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
          if (this.mappingType === 'view' && this.from === 'mappingMaintenance') {
            obj.queryType = '1'
          } else if (this.mappingType === 'edit' && this.from === 'mappingMaintenance') {
            obj.queryType = '2'
          }
          res = await nextOrPrevApi(obj)
        }
        if (res.code === 200) {
          if (res.data.rows.length > 0) {
            this.procList = res.data.rows
            this.locationIndex = res.data.rows[0].index
            await this.getTopTable(res.data.rows[0].index, res.data.rows[0].recordId)
            await this.mapInfoFn(res.data.rows[0]) // 根据机构药品内容获取知识库对应的匹配结果
            this.orgDrugId = res.data.rows[0].orgDrugId
            if (this.isPreviewData) {
              await this.getPreviewData(res.data.rows[0].orgDrugId)
            }
          }
        } else if (res.code === 10011) {
          this.$message({
            message: '当前已经是第一数据，请重新选择！',
            type: 'warning'
          })
          return false
        }
      }

    },
    // 下一条
    async nextStep() {
      this.resetClick() // 重置
      let obj = {}
      obj.index = this.locationIndex + 1
      obj.recordId = this.recordId
      obj.cacheFlag = 1
      let res = null
      if (this.from === 'self') {
        res = await indexFormList(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        if (this.mappingType === 'view' && this.from === 'mappingMaintenance') {
          obj.queryType = '1'
        } else if (this.mappingType === 'edit' && this.from === 'mappingMaintenance') {
          obj.queryType = '2'
        }
        res = await nextOrPrevApi(obj)
      }
      if (res.code === 200) {
        if (res.data.rows.length > 0) {
          this.procList = res.data.rows
          this.locationIndex = res.data.rows[0].index
          await this.getTopTable(res.data.rows[0].index, res.data.rows[0].recordId)
          await this.mapInfoFn(res.data.rows[0]) // 根据机构药品内容获取知识库对应的匹配结果
          this.orgDrugId = res.data.rows[0].orgDrugId
          if (this.isPreviewData) {
            await this.getPreviewData(res.data.rows[0].orgDrugId)
          }
        }
      } else if (res.code === 10002) {
        this.$message({
          message: '当前已经是最后一条，请重新选择！',
          type: 'warning'
        })
        return false
      }
    },
    // 药品查询
    async handleDrugQuery(val) {
      this.resetClick() // 重置
      // console.log(val)
      if (val === true) {
        // console.log('val - true')
        this.procListQuery.locationSearch = true
      } else {
        // console.log('val - false')
        this.procListQuery.locationSearch = false
      }
      this.isResult = true
      this.procLoading = true
      // console.log(this.recordId)
      this.procListQuery.recordId = this.recordId
      let res = null
      if (this.from === 'self') {
        res = await procList(this.procListQuery)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        if (this.mappingType === 'view' && this.from === 'mappingMaintenance') {
          this.procListQuery.queryType = '1'
        } else if (this.mappingType === 'edit' && this.from === 'mappingMaintenance') {
          this.procListQuery.queryType = '2'
        }
        res = await getDrugListApi(this.procListQuery)
      }
      if (res.code === 200) {
        this.procLoading = false
        if (res.data.rows) {
          this.locationIndex = 0
          this.procList = res.data.rows
        } else{
          this.procList = []
        }
        // 定位查找数据清空
        // if(res.data.rows) {
        //   // this.locationIndex = res.data.rows[0].index
        //   this.locationIndex = 0
        // }
        this.pageNum = 2 // 重置pageNum
      } else if (res.code !== 200 && res.code !== 500) {
        // this.$message({
        //   type: 'warning',
        //   message: res.msg
        // })
      }
    },
    signDrugChange(val) {
      this.checkedDrugSign = val
      this.isDrug = false
      var v= val.find(value => value===6);
      if(v !== undefined) {
        this.isDrug = true
      } else{
        this.isDrug = false
      }
      this.isDrugEmpty = false
      if (this.checkedDrugSign.length == 0){
        this.isDrugEmpty = true
      } else {
        this.isDrugEmpty = false
      }
      // console.log(v);// 存在值 返回该元素
      // if (this.checkedDrugSign === 2) { // 包装材料
      //   // this.labelName = '药品包装'
      //   this.labelName = '包装材料'
      // } else if (this.checkedDrugSign === 1) { // 药品包装
      //   // this.labelName = '包装材料'
      //   this.labelName = '药品包装'
      // } else if (this.checkedDrugSign === 3) { // 药品
      //   this.labelName = '药品'
      // } else if (this.checkedDrugSign === 4) { // 制剂规格
      //   this.labelName = '制剂规格'
      // } else if (this.checkedDrugSign === 5) { // 制剂
      //   this.labelName = '制剂'
      // } else if (this.checkedDrugSign === 6) { // 药物
      //   this.labelName = '药物'
      //   this.isDrug = true
      //   // 当选择药物时候，通用名查询隐藏
      // }
      this.pdpListQuery.queryTypeList = this.checkedDrugSign
      this.handlePdpDrugQuery()
    },
    // 点击某一行药品数据
    async procListTableRowClick(row) {
      this.resetClick() // 重置
      // console.log(row)
      this.procRowList = {}
      this.pdpRowList = {}
      await this.getTopTable(row.index, row.recordId)
      await this.mapInfoFn(row) // 根据机构药品内容获取知识库对应的匹配结果
      this.orgDrugId = row.orgDrugId
      if (this.isPreviewData) {
        await this.getPreviewData(row.orgDrugId)
      }
    },
    // pdpListTableRowClick
    pdpListTableRowClick(row) {
      // console.log(row)
      this.pdpRowList = row
      // if (this.pdpRowList === false) {
      //   this.isSecondQueryCopy = false
      // }
    },
    // F6查询
    firstQueryChange() {
      this.secondQuery = false // 二次查询关闭
      this.pdpListQuery.isSecondLookup = this.secondQuery
      this.handlePdpDrugQuery() // 划词二次查询按钮仅做开关操作，不调用查询接口
    },
    // 二次查询
    secondQueryChange() { // 划词二次查询开关是否打开操作
      this.secondQuery = true // 二次查询打开
      this.pdpListQuery.isSecondLookup = this.secondQuery
      this.handlePdpDrugQuery() // 划词二次查询按钮仅做开关操作，不调用查询接口
    },
    // 输入框是否划词二次查询
    complexSearchValueInput(val) {
      // console.log(val)
      // console.log(this.secondQuery)
      if (this.secondQuery === false) { // 划词二次查询关闭
        this.pdpListQuery.searchValue = val
      } else {
        // console.log(this.isSecondQueryCopy)
        if ( this.isSecondQueryCopy === false) { // 划词二次查询情况下未复制
          this.pdpListQuery.searchValue = val
        } else {
          if ( this.isSecondQC === false) { // 划词二次查询情况下未复制
            this.pdpListQuery.searchValue = val
          }
        }
      }
    },
    // 知识库对应的药品信息查找
    async handlePdpDrugQuery(drugId) {
      if (this.secondQuery === true) { // 二次查询
        // console.log('二次查询')
      } else { // 非二次查询
        this.isSecondQueryCopy = false
        this.isSecondQC = false
        let queryTypeList = this.pdpListQuery.queryTypeList
        this.pdpListQuery.queryTypeList = queryTypeList
        // console.log('非二次查询')
        this.pdpListQuery.drugName = null // 药品名称等
        this.pdpListQuery.tradeName = null // 商品名
        this.pdpListQuery.manufacturerName = null // 生产厂家
        this.pdpListQuery.approvalNo = null // 批准文号
        this.pdpListQuery.searchValue = this.complexSearchValue
      }
      this.pdpListQuery.queryTypeList = this.checkedDrugSign
      if(this.complexSearchValue === ''||this.complexSearchValue === null || this.complexSearchValue === undefined) {
        this.pdpRowList = {} // 清空
      }
      this.isTwoResult = true
      // console.log(drugId)
      if (drugId) {
        this.pdpListQuery.drugId = drugId
        this.pdpListQuery.isGenericNameSearch = true
      } else {
        if (this.pdpListQuery.isSecondLookup === true) {
          this.pdpListQuery.drugId = this.drugId
        } else {
          this.drugId = ''
          this.drugTym = ''
          this.pdpListQuery.isGenericNameSearch = false
          this.pdpListQuery.drugId = ''
          // this.pdpListQuery.isGenericNameSearch = false
        }
      }
      this.pdpLoading = true
      let res = null
      if (this.from === 'self') {
        res = await pdpList(this.pdpListQuery)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        this.pdpListQuery.dkbVersion = this.dkbVersion
        res = await searchDrugOfKnowledgeApi(this.pdpListQuery)
      }
      if (res.code === 200) {
        this.pdpLoading = false
        // console.log(res)
        this.pdpTableData = res.data
      } else if (res.code !== 200 && res.code !== 500) {
        // this.$message({
        //   type: 'warning',
        //   message: res.msg
        // })
      }
    },
    getTxtContent(e, val) {
      let txt = window.getSelection()
      if (txt.toString().length > 0 ){//当选中内容为空时，阻止事件发生
        this.isSecondQueryCopy = true
        this.isSecondQC = true
        let txt1=txt.toString();//得到的选中的文本是一个对象，需要转化为字符串
        // console.log(txt1)
        this.complexSearchValue = txt1
        if (val === 'mc') {
          this.pdpListQuery.drugName = txt1 // 药品名称等
        } else if (val === 'spm') {
          this.pdpListQuery.tradeName = txt1 // 商品名
        } else if (val === 'sccj') {
          this.pdpListQuery.manufacturerName = txt1 // 生产厂家
        } else if (val === 'pzwh') {
          this.pdpListQuery.approvalNo = txt1 // 批准文号
        }
        this.isSecondQC = false
        // this.pdpListQuery.searchValue = txt1
      }else{
        window.event? window.event.cancelBubble = true : e.stopPropagation();
      }

      // if (this.secondQuery === true) { // 划词二次查询打开
      //   let txt = window.getSelection()
      //   if (txt.toString().length > 0 ){//当选中内容为空时，阻止事件发生
      //     this.isSecondQueryCopy = true
      //     this.isSecondQC = true
      //     let txt1=txt.toString();//得到的选中的文本是一个对象，需要转化为字符串
      //     // console.log(txt1)
      //     this.complexSearchValue = txt1
      //     if (val === 'mc') {
      //       this.pdpListQuery.drugName = txt1 // 药品名称等
      //     } else if (val === 'spm') {
      //       this.pdpListQuery.tradeName = txt1 // 商品名
      //     } else if (val === 'sccj') {
      //       this.pdpListQuery.manufacturerName = txt1 // 生产厂家
      //     } else if (val === 'pzwh') {
      //       this.pdpListQuery.approvalNo = txt1 // 批准文号
      //     }
      //     this.isSecondQC = false
      //     // this.pdpListQuery.searchValue = txt1
      //   }else{
      //     window.event? window.event.cancelBubble = true : e.stopPropagation();
      //   }
      // } else { // 划词二次查询关闭
      //   let txt = window.getSelection()
      //   if (txt.toString().length > 0 ){//当选中内容为空时，阻止事件发生
      //     this.isSecondQueryCopy = true
      //     let txt1=txt.toString();//得到的选中的文本是一个对象，需要转化为字符串
      //     this.complexSearchValue = txt1
      //     this.pdpListQuery.searchValue = txt1
      //   }else{
      //     window.event? window.event.cancelBubble = true : e.stopPropagation();
      //   }
      // }
    },
    commonNameClick() {
      this.commnonNameDialogVisible = true
      this.commnonNameList.searchValue = null
      this.commnonNameList.pageNum = 1
      this.commnonNameList.searchValue = this.complexSearchValue
      this.queryCommnonNameList()
    },
    /** 搜索按钮操作 */
    queryCommnonNameList() {
      // this.isTwoResult = true
      this.commnonNameList.pageNum = 1
      this.selectCommnonNameList()
    },
    commnonNameHandleClose() {
      this.commnonNameDialogVisible = false
    },
    // 通用名数据查找
    async selectCommnonNameList() {
      this.loading = true
      // console.log(this.commnonNameList.searchValue)
      if (this.from === 'self') {
        search(this.commnonNameList).then(response => {
          this.commnonNameList.total=response.data.total
          this.commnonNameOptions = response.data.rows
          // // console.log(response)
          this.total = response.data.total
          this.loading = false
        })
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        this.commnonNameList.dkbVersion = this.dkbVersion
        let res = await commonNameSearchApi(this.commnonNameList)
        if (res.code === 200) {
          this.commnonNameList.total=response.data.total
          this.commnonNameOptions = response.data.rows
          this.total = response.data.total
          this.loading = false
        } else if (res.code !== 200 && res.code !== 500) {
          // this.$message({
          //   type: 'warning',
          //   message: res.msg
          // })
        }
      }

    },
    // 通用名选择药物
    choiceDrug(row) {
      // console.log(row)
      if (row.code) {
        this.commnonNameDialogVisible = false
        this.drugId = row.code
        this.drugTym = row.name
        this.handlePdpDrugQuery(this.drugId)
      }
    },
    statisticsHandleClose() {
      this.statisticsDialogVisible = false
    },
    // 取消匹配
    async clearEmpty() {
      if (this.procRowList.status === '1') {
        let _this = this
        this.$confirm('当前数据已匹配, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.canclePp()
        }).catch(() => {
        });
      } else if (this.procRowList.status === '2') {
        let _this = this
        this.$confirm('当前数据为非药品, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.canclePp()
        }).catch(() => {
        });
      } else if (this.procRowList.status === '3') {
        let _this = this
        this.$confirm('当前数据不匹配, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.canclePp()
        }).catch(() => {
        });
      } else { // 取消匹配
        this.$message({
          message: '当前数据已为未匹配状态,不可操作',
          type: 'warning'
        })
        return false
      }
    },
    // 取消匹配
    async canclePp() {
      this.resetClick() // 重置
      let obj = {}
      obj.index = this.procRowList.index
      obj.orgDrugId = this.procRowList.orgDrugId
      obj.orgId = this.orgId
      obj.recordId = this.recordId
      let res = null
      if (this.from === 'self') {
        res = await clear(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        res = await clearMatchDataApi(obj)
      }
      if (res.code === 200) {
        // console.log(res)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.pdpRowList = {} // 匹配数据值清空
        this.getTopTable(this.procRowList.index, this.procRowList.recordId)
        this.checkedUnmatched = [] // 不匹配原因清空
        this.isMappingEdit = true // 匹配关系被修改
      } else {
        // this.$message({
        //   message: res.msg,
        //   type: 'warning'
        // })
        return false
      }
    },
    // 非药品
    async nonDrugClick() {
      if (this.procRowList.status === '1') {
        let _this = this
        this.$confirm('当前数据已匹配, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.nonDrugClickFn()
        }).catch(() => {
        });
      } else if (this.procRowList.status === '2') {
        this.$message({
          message: '当前数据已为非药品状态,不可操作',
          type: 'warning'
        })
        return false
      } else if (this.procRowList.status === '3') {
        let _this = this
        this.$confirm('当前数据不匹配, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.nonDrugClickFn()
        }).catch(() => {
        });
      } else { // 未匹配
        this.nonDrugClickFn()
      }
    },
    // 非药品操作
    async nonDrugClickFn() {
      this.resetClick() // 重置
      let obj = {}
      obj.index = this.procRowList.index
      obj.mapResult = 2
      obj.orgDrugId = this.procRowList.orgDrugId
      obj.orgId = this.orgId
      obj.recordId = this.recordId
      let res = null
      if (this.from === 'self') {
        res = await save(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        obj.dkbVersion = this.dkbVersion
        res = await saveMatchDataApi(obj)
      }
      if (res.code === 200) {
        // console.log(res)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.pdpRowList = {} // 匹配数据值清空
        this.getTopTable(this.procRowList.index, this.procRowList.recordId)
        this.checkedUnmatched = [] // 不匹配原因清空
        this.isMappingEdit = true // 匹配关系被修改
      } else {
        // this.$message({
        //   message: res.msg,
        //   type: 'warning'
        // })
        return false
      }
    },
    // 不匹配
    async MismatchClick() {
      if (this.procRowList.status === '1') {
        let _this = this
        this.$confirm('当前数据已匹配, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.MismatchClickFn()
        }).catch(() => {
        });
      } else if (this.procRowList.status === '2') {
        let _this = this
        this.$confirm('当前数据为非药品, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.MismatchClickFn()
        }).catch(() => {
        });
      } else if (this.procRowList.status === '3') {
        this.$message({
          message: '当前数据已为不匹配状态,不可操作',
          type: 'warning'
        })
        return false
      } else { // 未匹配
        this.MismatchClickFn()
      }
    },
    // 未匹配操作
    async MismatchClickFn() {
      this.resetClick() // 重置
      if (this.checkedUnmatched.length === 0) {
        this.$message({
          message: '请选择不匹配原因！',
          type: 'warning'
        })
        return false
      } else {
        let obj = {}
        obj.index = this.procRowList.index
        obj.mapResult = 3
        obj.orgDrugId = this.procRowList.orgDrugId
        obj.mapFailReason = this.checkedUnmatched
        obj.orgId = this.orgId
        obj.recordId = this.recordId
        let res = null
        if (this.from === 'self') {
          res = await save(obj)
        } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
          obj.dkbVersion = this.dkbVersion
          res = await saveMatchDataApi(obj)
        }
        if (res.code === 200) {
          // console.log(res)
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.pdpRowList = {} // 匹配数据值清空
          this.getTopTable(this.procRowList.index, this.procRowList.recordId)
          this.isMappingEdit = true // 匹配关系被修改
        } else {
          // this.$message({
          //   message: res.msg,
          //   type: 'warning'
          // })
          return false
        }
      }
    },
    // 精准匹配
    async accurateMatching() {
      if (this.procRowList.status === '1') {
        let _this = this
        this.$confirm('当前数据已匹配, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.ppFn()
        }).catch(() => {
        });
      } else if (this.procRowList.status === '2') {
        let _this = this
        this.$confirm('当前数据为非药品, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.ppFn()
        }).catch(() => {
        });
      } else if (this.procRowList.status === '3') {
        let _this = this
        this.$confirm('当前数据不匹配, 是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.ppFn()
        }).catch(() => {
        });
      } else { // 取消匹配
        this.ppFn()
      }
    },
    // 精准匹配操作
    async ppFn() {
      this.resetClick() // 重置
      // console.log(this.procRowList)
      let obj = {}
      obj.index = this.procRowList.index
      obj.mapResult = 1
      obj.orgDrugId = this.procRowList.orgDrugId
      obj.dkbId = this.pdpRowList.dkbId
      obj.mapLevel = this.pdpRowList.mapLevel
      obj.orgId = this.orgId
      obj.recordId = this.recordId
      obj.genericName = this.pdpRowList.genericName
      let res = null
      if (this.from === 'self') {
        res = await save(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        obj.dkbVersion = this.dkbVersion
        res = await saveMatchDataApi(obj)
      }
      if (res.code === 200) {
        // console.log(res)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.procListQuery.searchValue = this.procRowList.orgDrugId
        // this.handleLocateFind() // 定位查找
        await this.getOneTable(this.procRowList.index, this.procRowList.recordId)
        await this.getTopTable(this.procRowList.index, this.procRowList.recordId)
        this.procListQuery.searchValue = '' // 清除input框中被匹配药品的ID
        this.isMappingEdit = true // 匹配关系被修改
      } else {
        // this.$message({
        //   message: res.msg,
        //   type: 'warning'
        // })
        return false
      }
    },
    // 统计
    handleStatistics() {
      this.statisticsDialogVisible = true
      this.submitDataFn()
    },
    // 获取统计数据
    async submitDataFn() {
      let recordId = this.recordId
      let res = await submit(recordId)
      if (res.code === 200) {
        // console.log(res)
        this.statisticsData = res.data
      } else if (res.code !== 200 && res.code !== 500) {
        // this.$message({
        //   type: 'warning',
        //   message: res.msg
        // })
      }
    },
    // 第一次点击匹配完成
    async firstFinish() {
      // console.log(this.recordId)
      let obj = {
      }
      obj.recordId = this.recordId
      obj.enforceExecute = false
      let res = null
      if (this.from === 'self') {
        res = await finish(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        res = await finishMatchedApi(obj)
      }
      // console.log(res)
      if (res.code === 6666) {
        // console.log(res)
        // this.$message({
        //   type: 'info',
        //   message: res.msg
        // });
        let _this = this
        this.$confirm(res.msg + ',是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await _this.secondFinish()
          // this.$message({
          //   type: 'success',
          //   message: '匹配完成!'
          // });
        }).catch(() => {
          this.isAllData = false
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      } else if (res.code === 10017) { // 存在一对多
        this.dialogVisible = true
        this.isAllData = false
      } else if (res.code === 200) {
        // console.log(res)
        this.$message({
          type: 'success',
          message: res.msg
        });
        this.$store.dispatch('tagsView/delView', this.$route)
        if (this.from === 'self') {
          this.$router.push('/dm/drugMetadataMatching')
        } else if (this.from === 'knowledgeBaseVersionUpgrade') {
          this.$router.push('/dm/knowledgeBaseVersionUpgrade')
        } else if (this.from === 'mappingMaintenance') {
          this.$router.push('/dm/mappingMaintenance')
        }
        this.isAllData = false
      } else {
        this.isAllData = false
        if (res.code === 10013) { // 撤销之后点已撤回
          this.$store.dispatch('tagsView/delView', this.$route)
          this.$router.push('/dm/drugMetadataMatching')
        }
      }
    },
    // 第二次点击匹配完成
    async secondFinish() {
      // console.log(this.recordId)
      let obj = {
      }
      obj.recordId = this.recordId
      obj.enforceExecute = true
      let res = null
      if (this.from === 'self') {
        res = await finish(obj)
      } else if (this.from === 'knowledgeBaseVersionUpgrade' || this.from === 'mappingMaintenance') {
        res = await finishMatchedApi(obj)
      }
      if (res.code === 200) {
        // console.log(res)
        this.$store.dispatch('tagsView/delView', this.$route)
        if (this.from === 'self') {
          this.$router.push('/dm/drugMetadataMatching')
        } else if (this.from === 'knowledgeBaseVersionUpgrade') {
          this.$router.push('/dm/knowledgeBaseVersionUpgrade')
        } else if (this.from === 'mappingMaintenance') {
          this.$router.push('/dm/mappingMaintenance')
        }
        this.isAllData = false
      } else {
        this.isAllData = false
      }
    },
    // 匹配完成
    async mappingDone() {
      this.isAllData = true
      await this.firstFinish()
    },
    // 映射关系维护场景：保存修改
    async mappingEdit() {

    },
    // 退出
    handleReback() {
      this.$store.dispatch('tagsView/delView', this.$route)
      if (this.from === 'self') {
        this.$router.push('/dm/drugMetadataMatching')
      } else if (this.from === 'knowledgeBaseVersionUpgrade') {
        this.$router.push('/dm/knowledgeBaseVersionUpgrade')
      } else if (this.from === 'mappingMaintenance') {
        this.$router.push('/dm/mappingMaintenance')
      }
    },
    setErrorColor({ row, column, rowIndex, columnIndex }) {
      if(column.property === "originName" || column.property === "stdName") {
        if (row.result === '3') {
          return 'risk-row';
        } else if (row.result === '4') {
          // return 'success-row';
          return 'optimize-row';
        }
      }
    },
    codeMatchingClick() {
      const orgId = this.orgId
      const recordId = this.recordId
      // console.log(this.$router)
      this.$router.push({
        path: '/drugMetadataMatching/codeMapping/' + orgId,
        query: {
          recordId: this.recordId
        }
      })
      this.dialogVisible = false
    },
  }
  // beforeRouteEnter(to, from ,next) {
  //   console.log(to)
  //   const isLookShowHH = to.query && to.query.isLookShow
  //   if (isLookShowHH) {
  //     to.meta.title = '药品查看'
  //   } else {
  //     to.meta.title = '药品匹配'
  //   }
  //   next((vm) => {})
  // }
};
</script>
<style lang="scss" scoped>
.vdr{
  //background: #FFFFFF;
  //box-shadow: 0px 0px 6px 0px rgba(62, 62, 62, 0.6);
  //border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 0px 18px 0px rgba(84, 84, 84, 0.8);
  border-radius: 8px;
}
.infoClose{
  padding:0px 20px;
  height: 40px;
  line-height: 40px;
  background: #EBF0FA;
  border-radius: 4px 4px 0px 0px;
  span{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 18px;
  }
  .el-button{
    float: right;
    i{
      font-size: 20px;
    }
  }
}
.infoName{
  margin-top: 5px;
  text-indent: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
}
.infoClass{
  //text-indent: 20px;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  padding:0px 20px;
}
.vdr.active:before{
  outline: none!important;
}
.headerClass{
  padding: 0;
  margin: 0;
  .headerLiClass{
    line-height: 36px;
    padding-right: 10px;
    display: inline-block;
  }
}
.drugElementData{
  margin-bottom: 10px;
}
.stateContent{
  span {
    //width: 213px;
    //height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 33px;
    margin-right: 10px;
    .stateResult{
      color: #E4860A;
      font-style: normal;
    }
    .uploadMethod{
      color: #333333;
      font-style: normal;
    }
  }
}
.menuChoice{
  padding:0;
  margin:0;
  display: block;
  width: 100%;
  background: #FFFFFF;
  height: 40px;
  li{
    display: inline-block;
    a{
      border-bottom: 2px solid #FFFFFF;
      display: inline-block;
      padding:0;
      margin:0;
      line-height: 40px;
      text-align: center;
      height: 40px;
      list-style: none;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      //background: #FFFFFF;
      color: #333333;
      padding:0px 8px;
    }
  }
}
.menuLiActive a{
  border-bottom: 2px solid #2D5AFA!important;
  color:#2D5AFA!important;;
  //background: #E6ECFA!important;
}
.firstMenuChoice{
  padding:0;
  margin:0;
  display: block;
  width: 100%;
  background: #FFFFFF;
  height: 36px;
  li{
    display: inline-block;
    a{
      border: 1px solid #96B1FA;
      display: inline-block;
      padding:0;
      margin:0;
      text-align: center;
      width: 90px;
      height: 36px;
      line-height: 36px;
      list-style: none;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      //background: #FFFFFF;
      color: #333333;
      padding:0px 15px;
    }
  }
}
.firstmenuLiActive a{
  border: 1px solid #2D5AFA!important;
  color:#2D5AFA!important;;
  background: #E6ECFA!important;
}
.thirdMenuChoice{
  padding:0;
  margin:0;
  display: block;
  width: 100%;
  background: #FFFFFF;
  height: 36px;
  li{
    display: inline-block;
    a{
      border: 1px solid #96B1FA;
      display: inline-block;
      padding:0;
      margin:0;
      text-align: center;
      width: 90px;
      height: 36px;
      line-height: 36px;
      list-style: none;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      //background: #FFFFFF;
      color: #333333;
      padding:0px 15px;
    }
  }
}
.thirdmenuLiActive a{
  border: 1px solid #2D5AFA!important;
  color:#2D5AFA!important;;
  background: #E6ECFA!important;
}
.platformDkbWrap{
  border: 1px solid #dfe6ec;
  overflow-y: auto;
  .platformDkb{
    font-weight: bold;
    word-break: break-word;
    background-color: #E8EEFC;
    color: #333333;
    padding: 0px;
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-bottom: 1px solid #dfe6ec;
  }
}
.yys{
  display: inline-block;
  background: #D8E2FF;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2B60F8;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.wys{
  display: inline-block;
  background: #DDDDDD;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.searchUl{
  padding:0;
  margin: 0;
  li{
    list-style: none;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .searchLiLeft{
    display:block;
    float: left;
  }
  .searchLiRight{
    display:block;
    float: left;
  }
  .searchLiLeftBtn{
    display:block;
    float: left;
    margin:0px 5px 5px 0px;
  }
}
.bottomWrap{
  height: 597px;
  margin-top: 10px;
  .bottomLeft{
    padding:0;
    background: #ffffff;
    border: 1px solid #CFDBFB;
    height: 570px;
    margin-right: 10px;
    .el-header{
      padding:0px 10px!important;
      height: 40px!important;
      line-height: 40px!important;
      background: #E8EEFC;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
    }
    .el-form{
      margin-top: 20px;
    }
    .el-form-item{
      margin-bottom: 10px;
    }
  }
  .bottomRight{
    padding:0;
    height: 550px;
    .el-header{
      padding:0px!important;
      height: 85px!important;
      .el-form-item{
        margin-bottom: 0px!important;
      }
    }
    .el-main{
      padding:0px!important;
      .mappingBottomRight{
        margin-top: 10px;
        .el-aside{
          padding:0;
          background: #ffffff;
          border: 1px solid #CFDBFB;
          margin:0px!important;
          margin-right: 10px;
          .el-header{
            padding:0px 10px!important;
            height: 40px!important;
            line-height: 40px!important;
            background: #E8EEFC;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
          }
          .el-form{
            margin: 13px 0px;
            padding-right:10px;
          }
          .el-form-item{
            margin-bottom: 10px;
          }
        }
        .remarkWrap{
          .remarkForm{
            .el-form-item{
              margin-bottom: 5px!important;
            }
          }
        }
      }
    }
    //background:#BDD3FF;
  }
}
// 区分大小屏
@media screen and (max-width: 1440px) {
  #topTable{
    height: 100px!important;
  }
  #plqSql{
    width: 100px!important;
  }
  .bottomWrap{
    height: 450px;
    // 表格高度缩小
    .bottomLeft{
      height: 420px;
      .el-main{
        padding:0px 10px 0px 0px!important;
      }
      .el-form{
        margin-top:5px!important;
      }
      .el-form-item{
        height: 30px!important;
        line-height: 30px!important;
        margin-bottom: 6px!important;
      }
      >>>.el-input__inner{
        height: 30px!important;
        line-height: 30px!important;
      }
      .el-input{
        height: 30px!important;
        line-height: 30px!important;
      }
    }
    .mappingBottomRight{
      .remarkWrap{
        height: 185px;
        .el-form-item{
          margin-bottom: 5px;
          height: 30px!important;
          line-height: 30px!important;
        }
        .el-input__inner{
          height: 30px!important;
          line-height: 30px!important;
        }
        .el-input{
          height: 30px!important;
          line-height: 30px!important;
        }
        .remarkForm{
          .el-form-item{
            //margin-bottom: 3px!important;
          }
          .el-checkbox{
            line-height: 28px!important;
          }
        }
      }
    }
  }

}
.statisticsTitle{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  height: 26px;
  line-height: 26px;
}
.statisticsBlueNum{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2B60F8;
  height: 26px;
  line-height: 26px;
}
.statisticsContent{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  height: 26px;
  line-height: 26px;
}
.statisticsRedNum{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FF3333;
  height: 26px;
  line-height: 26px;
}
.statisticsTitleNext{
  width: 130px;
  text-align: right;
  display: inline-block;
}
.statisticsTitleRight{
  width: 90px;
  text-align: right;
  display: inline-block;
}
.statisticsDiv{
  padding-top: 15px;
  margin-top: 10px;
  width: 140px;
  height: 78px;
  background: #D6E1FF;
  border: 1px solid #BDCEFF;
  border-radius: 6px;
  text-align: center;

}
</style>
<style lang="scss">
#mappingWrap .el-table__empty-text{
  line-height: 0px!important;
}
#mappingWrap{
  .el-table {
    .risk-row {
      background: #FFDBE1!important;
    }
    .optimize-row {
      background: #FFE9D1!important;
    }
  }
}
// 区分大小屏
@media screen and (max-width: 1440px){
  #mappingWrap{
    // 表格高度缩小
    .bottomLeft{
      .el-input__inner{
        height: 30px!important;
        line-height: 30px!important;
      }
    }
    .bottomRight{
      .el-input__inner{
        height: 30px!important;
        line-height: 30px!important;
      }
    }
  }
}
</style>
