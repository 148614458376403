/**知识库版本升级 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
// 根据机构名称模糊查找-最多返回前50条
export function getOrgDataApi(data) {
  return request({
    url: '/dm/dkb/upgrade/record/org/search',
    method: 'GET',
    params: data
  })
}
// 获取状态集合
export function getStatusListApi(data) {
  return request({
    url: '/dm/dkb/upgrade/record/org/search',
    method: 'GET',
    params: data
  })
}
// 知识库升级维护分页查询
export function getTableDataByPageApi(data) {
  return request({
    url: '/dm/dkb/upgrade/record/list',
    method: 'GET',
    params: data
  })
}
// 查看最新版的知识库版本信息
export function getInfoApi() {
  return request({
    url: '/dm/common/dkb/version/info',
    method: 'get'
  })
}
// 医院药品基本信息
export function drugBasicInfoApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/drug/info',
    method: 'get',
    params: data
  })
}
// 匹配完成
export function finishMatchedApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/finish',
    method: 'PUT',
    needSpical: true,
    params: data
  })
}
// 通用名查找
export function commonNameSearchApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/generic/search',
    method: 'GET',
    params: data
  })
}
// 上一条,下一条操作
export function nextOrPrevApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/index',
    method: 'GET',
    needSpical: true,
    params: data
  })
}
// 根据recordId获取该批次的药品集合(定位查找)
export function getDrugListApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/list',
    method: 'GET',
    params: data
  })
}
// 药品元数据映射清空(取消匹配)
export function clearMatchDataApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/mapping/clear',
    method: 'PUT',
    data: data
  })
}
// 药品元数据映射保存
export function saveMatchDataApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/mapping/save',
    method: 'PUT',
    data: data
  })
}
// 药品查询分页查找
export function searchDrugByPageApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/page',
    method: 'GET',
    needSpical: true,
    params: data
  })
}
// 知识库对应的药品信息查找
export function searchDrugOfKnowledgeApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/pdp/list',
    method: 'GET',
    params: data
  })
}
// 根据机构药品内容获取知识库对应的匹配结果
export function getMatchResultApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/pdp/map/info',
    method: 'GET',
    params: data
  })
}
// 药品查询初始化
export function getInitDataBySearchDrugApi(data) {
  return request({
    url: '/dm/dkb/upgrade/proc/proc/init',
    method: 'GET',
    params: data
  })
}
// 根据上传记录ID获取该批次的药品类型集合
export function getDrugTypeApi (recordId) {
  return request({
    url: '/dm/dkb/upgrade/proc/type/' + praseStrEmpty(recordId),
    method: 'get'
  })
}